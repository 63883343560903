import { nanoid } from '@reduxjs/toolkit';
import { BareSettingSlider } from 'components/giro3d/settingsMenu/inputs/SettingSlider';

export type Props = {
    value: number;
    min: number;
    max: number;
    step: number;
    title: string;
    icon?: string;
    unit?: string;
    onChange: (v: number) => void;
};

function Slider(props: Props) {
    const id = nanoid();
    return (
        <li>
            <label htmlFor={id}>
                <div>
                    <i className={props.icon} />
                </div>
                <div>
                    <label htmlFor={id}>{props.title}</label>
                </div>
                <div>
                    <BareSettingSlider
                        value={props.value}
                        min={props.min}
                        max={props.max}
                        step={props.step}
                        onChange={props.onChange}
                    />
                    <span>
                        {props.value.toFixed(0)}
                        {props.unit}
                    </span>
                </div>
            </label>
        </li>
    );
}

export default Slider;
