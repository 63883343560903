import TabHeader from 'components/flexLayout/TabHeader';
import { useMountEffect } from 'components/utils';
import { useAppDispatch, useAppSelector } from 'store';
import Grid from 'components/giro3d/SeismicGrid';
import { getService } from 'ServiceContainer';
import * as giro3dSlice from 'redux/giro3d';
import * as datasetsSlice from 'redux/datasets';
import { loadSeismicView, setClickedDataset, setSeismicDataset, unloadSeismicView } from 'redux/actions';
import HeaderButton from 'components/flexLayout/HeaderButton';
import { useEffect } from 'react';
import { useEventBus } from 'EventBus';
import { getBaseName } from 'types/SourceFile';
import SeismicGridSetting from '../settingsMenu/SeismicGridSettings';

const SeismicViewer = ({ seismicRef, inspectorRef }) => {
    const dispatch = useAppDispatch();
    const eventBus = useEventBus();
    const seismicView = getService('SeismicViewManager');
    const seismicViewReady = useAppSelector(giro3dSlice.isSeismicViewInitialized);
    const project = useAppSelector(datasetsSlice.currentProject);

    const dataset = useAppSelector(datasetsSlice.getSeismicDataset);
    const sourcefiles = useAppSelector(datasetsSlice.getSourceFiles(dataset.id));
    const sourcefile = useAppSelector(datasetsSlice.getSeismicSourceFile);

    useMountEffect(
        () => dispatch(loadSeismicView(seismicRef.current, inspectorRef.current, project)),
        () => dispatch(unloadSeismicView())
    );

    useEffect(() => {
        if (dataset && sourcefile) dispatch(setSeismicDataset(dataset, sourcefile));
    }, [sourcefile]);

    const fileIndex = sourcefiles?.indexOf(sourcefile);

    const selectFile = (index: number) => {
        if (index < 0 || index >= sourcefiles.length) throw new Error('File out of range');

        const newFile = sourcefiles[index];
        eventBus.dispatch('highlight-file', { dataset: dataset.id, file: newFile.id });
        dispatch(setClickedDataset(dataset.id, newFile.id));
        dispatch(datasetsSlice.setSeismicDataset({ dataset: dataset.id, sourceFile: newFile.id }));
    };

    return (
        <>
            <TabHeader
                left={undefined}
                center={<span>{`${dataset.name} - ${getBaseName(sourcefile)}`}</span>}
                right={
                    <>
                        <SeismicGridSetting key="grid-settings" />
                        <HeaderButton
                            button={{
                                name: 'Previous Line',
                                icon: 'fas fa-arrow-left',
                                onClick: () => selectFile(fileIndex - 1),
                                disabled: fileIndex === 0,
                            }}
                            key="next-line"
                        />
                        {fileIndex + 1}/{sourcefiles?.length ?? 1}
                        <HeaderButton
                            button={{
                                name: 'Next Line',
                                icon: 'fas fa-arrow-right',
                                onClick: () => selectFile(fileIndex + 1),
                                disabled: fileIndex === (sourcefiles?.length ?? 1) - 1,
                            }}
                            key="previous-line"
                        />
                    </>
                }
            />
            <div className="giro3d-seismic-container" ref={seismicRef} />
            {seismicViewReady ? <Grid instance={seismicView.getInstance()} /> : null}
        </>
    );
};

const SeismicViewerWrapper = ({ seismicRef, inspectorRef }) => {
    const dataset = useAppSelector(datasetsSlice.getSeismicDataset);
    if (dataset) return <SeismicViewer seismicRef={seismicRef} inspectorRef={inspectorRef} />;
    return <div className="tabContent">Select a Seismic dataset to begin viewing...</div>;
};

export default SeismicViewerWrapper;
