import { Color, Plane } from 'three';

import ElevationLayer from '@giro3d/giro3d/core/layer/ElevationLayer';
import { ElevationRange } from '@giro3d/giro3d/core';
import { ColorLayer } from '@giro3d/giro3d/core/layer';

import {
    Attribute,
    ColoringMode,
    HasAttributes,
    HasColoringMode,
    HasDraping,
    HasOpacity,
    HasOverlayColor,
    LayerState,
    getAttributeMinMax,
    hasAttributes,
    hasColoringMode,
    hasDraping,
    hasMasks,
} from 'types/LayerState';
import { updateGiro3DColorMap } from 'giro3d_extensions/LUT';
import ColorMap, { getLUT, type SerializedColorMap } from 'types/ColorMap';
import * as layersSlice from 'redux/layers';
import type { DatasetId, ScopeColorLayer, ScopeElevationLayer } from 'types/common';
import { useEventBus } from 'EventBus';
import HoveredItem from 'types/HoveredItem';
import Layer, {
    Settings as BaseSettings,
    ConstructorParams as BaseConstructorParams,
    LayerEventMap,
    HostView,
} from '../Layer';
import { DEFAULT_LAYER_SETTINGS, LAYER_TYPES } from '../../../services/Constants';
import LayerManager, { DrapingMode } from '../../LayerManager';
import LayerStateObserver from '../LayerStateObserver';
import IsHoverable from '../IsHoverable';

interface Settings extends BaseSettings {
    supportsColormap: boolean;
    isDraped: boolean;
    supportsDraping: boolean;
    zOffset: number;
    brightness: number;
    opacity: number;
    enableClippingRange: boolean;
    clippingRange: ElevationRange;
    pinned: boolean;
    supportsMasks: boolean;
    masks: Map<DatasetId, { giroLayers: ScopeElevationLayer[] }>;
    mode: string;
    overlayColor: Color;
    coloringMode: ColoringMode;
    colormap: SerializedColorMap;
}

export interface SupportsMasks {
    setMasks(datasetIds: DatasetId[]): void;
}

type RasterLayerState = LayerState & HasOpacity & HasDraping & HasOverlayColor & HasColoringMode & HasAttributes;

export interface ConstructorParams<TGiroLayer> extends BaseConstructorParams {
    supportsColormap?: boolean;
    layerManager: LayerManager;
    supportsMasks?: boolean;
    readableName: string;
    datasetType: LAYER_TYPES;
    /**
     * Min & max values for this layer
     */
    minmax?: ElevationRange;
    createLayer: () => Promise<TGiroLayer>;
}

const eventBus = useEventBus();

/**
 * Wraps a Giro3D layer.
 */
export default abstract class RasterLayer<
        TGiroLayer extends ScopeElevationLayer | ScopeColorLayer,
        TLayerState extends RasterLayerState = RasterLayerState,
    >
    extends Layer<Settings, LayerEventMap, TLayerState>
    implements IsHoverable
{
    readonly isHoverable = true as const;
    readonly minmax: ElevationRange;
    protected readonly _readableName: string;
    protected readonly _datasetType: LAYER_TYPES;
    protected getLayers: (id: DatasetId) => this[];
    private readonly createLayer: () => Promise<TGiroLayer>;
    private _masks: string[] = [];
    private _onOtherDatasetLoaded: (event: { id: DatasetId; view: HostView }) => void;
    private _colorMapProperties: {
        min: number;
        max: number;
        attributeName: string;
        lut: Color[];
    };

    layer: TGiroLayer;

    constructor(params: ConstructorParams<TGiroLayer>) {
        super(params);

        this._readableName = params.readableName;
        this._datasetType = params.datasetType;

        this.settings.supportsColormap = params.supportsColormap ?? false;
        this.settings.isDraped = false;
        this.settings.supportsDraping = hasDraping(this._layerState);
        this.settings.zOffset = DEFAULT_LAYER_SETTINGS.Z_OFFSET;
        this.settings.opacity = DEFAULT_LAYER_SETTINGS.OPACITY;
        this.settings.brightness = 0;
        this.settings.enableClippingRange = DEFAULT_LAYER_SETTINGS.CLIPPING;
        this.settings.clippingRange = DEFAULT_LAYER_SETTINGS.CLIPPING_RANGE;
        this.settings.pinned = undefined;
        this.settings.supportsMasks = params.supportsMasks ?? false;
        this.settings.masks = new Map();
        this.settings.coloringMode = ColoringMode.Colormap;

        this.createLayer = params.createLayer as () => Promise<TGiroLayer>;

        this.minmax = params.minmax;
    }

    protected override subscribeToStateChanges(observer: LayerStateObserver<TLayerState>): void {
        super.subscribeToStateChanges(observer);

        observer.subscribe(layersSlice.getOpacity(this._layerState), (v) => this.setOpacity(v));
        observer.subscribe(layersSlice.getOverlayColor(this._layerState), (v) => this.setOverlayColor(v));

        if (hasColoringMode(this._layerState)) {
            observer.subscribe(layersSlice.getCurrentColoringMode(this._layerState), (v) => this.setColoringMode(v));
        }

        if (hasAttributes(this._layerState)) {
            this.subscribeToAttributeChanges(this._layerObserver);
        }
    }

    protected subscribeToAttributeChanges(observer: LayerStateObserver<TLayerState>): void {
        observer.subscribe(layersSlice.getActiveAttributeAndColorMap(this._layerState), (v) =>
            this.setColormapProperties(v)
        );
    }

    protected subscribeToColorLayerSpecificStateChanges(observer: LayerStateObserver<TLayerState>): void {
        observer.subscribe(layersSlice.isDraped(this._layerState), (v) => this.setDraping(v));
        observer.subscribe(layersSlice.getUndrapedZOffset(this._layerState), (v) => this.setZOffset(v));
        observer.subscribe(layersSlice.isClippingRangeEnabled(this._layerState), (v) => this.setEnableClippingRange(v));
        observer.subscribe(layersSlice.getClippingRange(this._layerState), (v) => this.setClippingRange(v));
    }

    private setColoringMode(mode: ColoringMode) {
        this.settings.coloringMode = mode;

        this.applyColoringMode();
    }

    private applyColoringMode() {
        if (this.initialized) {
            switch (this.settings.coloringMode) {
                case ColoringMode.Colormap:
                    this.layer.colorMap.active = true;
                    break;
                default:
                    this.layer.colorMap.active = false;
                    break;
            }

            this.notifyLayerChange();
        }
    }

    hover(hover: boolean): void {
        this.setFootprintBrightness(hover ? 0.3 : 0);
        this.notifyLayerChange();
    }

    getHoverInfo(): HoveredItem {
        return {
            name: this._readableName,
            itemType: this._datasetType,
        };
    }

    private setColormapProperties(arg: { attribute: Attribute; colorMap: ColorMap }) {
        if (!arg) {
            return;
        }

        const { attribute, colorMap } = arg;

        const { min, max } = getAttributeMinMax(attribute, colorMap);
        this._colorMapProperties = {
            attributeName: attribute.name,
            min,
            max,
            lut: getLUT(colorMap),
        };

        this.applyColorMap();
    }

    private applyColorMap() {
        if (this.initialized) {
            updateGiro3DColorMap(this._colorMapProperties, this.layer.colorMap);

            this.notifyLayerChange();
        }
    }

    protected setOverlayColor(value: Color) {
        this.settings.overlayColor = value;
        if (this.initialized && (this.layer as ScopeElevationLayer).isElevationLayer) {
            this._layerManager.setBackgroundColor(this.layer as ScopeElevationLayer, value);
        }
    }

    protected override async initOnce() {
        this.layer = await this.createLayer();
        this.settings.isDraped = this.settings.supportsDraping;

        return this._layerManager.addLayer(this.layer).then(() => {
            this.initialized = true;
            this.postInitialization();
        });
    }

    protected override onDispose() {
        super.onDispose();
        if (this.layer) {
            this._layerManager.removeLayer(this.layer);
        }
        if (this._onOtherDatasetLoaded) {
            eventBus.unsubscribe('dataset-loaded', this._onOtherDatasetLoaded);
        }
    }

    private postInitialization() {
        if ((this.layer as ColorLayer).isColorLayer) {
            this.subscribeToColorLayerSpecificStateChanges(this._layerObserver);
        }

        if (hasMasks(this._layerState)) {
            this._onOtherDatasetLoaded = this.onOtherDatasetLoaded.bind(this);
            this._layerObserver.subscribe(layersSlice.getActiveMaskIds(this._layerState), (v) => this.setMasks(v));
            eventBus.subscribe('dataset-loaded', this._onOtherDatasetLoaded);
        }

        this.setOpacity(this.settings.opacity);

        if (hasColoringMode(this._layerState) && this.settings.coloringMode === ColoringMode.Colormap) {
            this.applyColorMap();
        }

        this.notifyLayerChange();
    }

    private onOtherDatasetLoaded(event: { id: DatasetId; view: HostView }) {
        if (event.view !== this._hostView) {
            // This event does not concern our view (minimap, seismic, etc).
            return;
        }

        // This layer might have been loaded before its masks. In this case we could not
        // create the masks on initialization because their layers would not event exist.
        // We need to update the masks as soon as one mask dataset is loaded.
        if (this._masks.includes(event.id)) {
            this.updateMasks();
        }
    }

    private checkMaskSupport() {
        if (!this.settings.supportsMasks) {
            throw new Error('This layer does not support masks. An illegal action was performed.');
        }
    }

    private setMasks(ids: DatasetId[]) {
        this._masks = ids;

        this.updateMasks();
    }

    private updateMasks() {
        const ids = this._masks;
        for (const attachedMask of [...this.settings.masks.keys()]) {
            if (!ids.includes(attachedMask)) {
                this.detachMask(attachedMask);
            }
        }

        this.settings.masks.clear();

        for (const id of ids) {
            if (!this.settings.masks.has(id)) {
                this.attachMask(id);
            }
        }
    }

    /**
     * Attaches the mask to this layer.
     * @param datasetId The ID of the dataset that acts as a mask.
     */
    private async attachMask(datasetId: DatasetId) {
        this.checkMaskSupport();

        if (this.settings.masks.has(datasetId)) {
            throw new Error(`invalid operation: this layer already has mask ${datasetId} attached`);
        }

        // Only Giro3D layers can act as mask emitters and receivers
        const scopeLayers = this.getLayers(datasetId);

        const giroLayers: ScopeElevationLayer[] = [];
        this.settings.masks.set(datasetId, { giroLayers });

        for (const scopeLayer of scopeLayers) {
            if (!(scopeLayer.layer instanceof ElevationLayer)) {
                throw new Error('invalid mask source: should be a Giro3D elevation layer.');
            }
            const giroLayer = scopeLayer.layer;

            giroLayers.push(giroLayer);

            this._layerManager.attachMask({
                receiver: this.layer as ScopeElevationLayer,
                emitter: giroLayer as ScopeElevationLayer,
            });
        }
    }

    protected setClippingPlane(plane: Plane) {
        if (this.initialized) {
            this._layerManager.setClippingPlane(this.layer, plane);
        }
    }

    /**
     * Detaches the mask from the layer.
     * @param datasetId The mask ID.
     */
    private detachMask(datasetId: DatasetId) {
        this.checkMaskSupport();

        if (!this.settings.masks.has(datasetId)) {
            throw new Error(`invalid operation: this layer does not have mask ${datasetId} attached`);
        }
        const giroLayers = this.settings.masks.get(datasetId).giroLayers;
        const receiver = this.layer as ScopeElevationLayer;

        for (const emitter of giroLayers) {
            this._layerManager.detachMask({ receiver, emitter });
        }
        this.settings.masks.delete(datasetId);
    }

    protected notifyLayerChange() {
        if (!this.initialized) {
            // No layer to notify change on
            return;
        }
        this._layerManager.notifyChange(this.layer);
        super.notifyLayerChange();
    }

    get3dElement(): TGiroLayer {
        return this.layer;
    }

    setGetLayersFunction(getLayers: (id: DatasetId) => this[]) {
        this.getLayers = getLayers;
    }

    async doSetVisibility(value: boolean) {
        this._layerManager.setVisibility(this.get3dElement(), value);
    }

    setOpacity(opacity: number) {
        this.settings.opacity = opacity;
        if (this.initialized) {
            this._layerManager.setOpacity(this.get3dElement(), opacity);
        }
    }

    setBrightness(brightness: number) {
        this.settings.brightness = brightness;
        if (this.initialized) {
            this._layerManager.setBrightness(this.get3dElement(), brightness);
        }

        this.setFootprintBrightness(brightness);
    }

    private setFootprintBrightness(brightness: number) {
        this._layerManager.setFootprintBrightness(this.sourceFileId, brightness);
    }

    setZOffset(z: number) {
        this.settings.zOffset = z;
        if (this.initialized) {
            if (!this.settings.isDraped && this.settings.supportsDraping) {
                this._layerManager.setUndrapedLayerHeight(this.get3dElement() as ScopeColorLayer, z);
            }
        }
    }

    setClippingRange(value: ElevationRange) {
        this.settings.clippingRange = value;
        if (this.settings.supportsDraping) {
            this._updateClippingRange();
        }
    }

    setEnableClippingRange(value: boolean) {
        this.settings.enableClippingRange = value;
        if (this.settings.supportsDraping) {
            this._updateClippingRange();
        }
    }

    setDraping(v: boolean) {
        this.settings.isDraped = v;
        if (this.initialized) {
            if (this.settings.supportsDraping) {
                const drapingMode = v ? DrapingMode.Draped : DrapingMode.Undraped;
                this._layerManager.setDrapingMode(this.layer as ScopeColorLayer, drapingMode);
                this._updateClippingRange();
            }
        }
    }

    _updateClippingRange() {
        if (this.initialized) {
            this._layerManager.setClippingRange(
                this.layer as ScopeColorLayer,
                this.settings.enableClippingRange && this.settings.isDraped ? this.settings.clippingRange : null
            );
        }
    }

    getOverlayColor() {
        return this.settings.overlayColor;
    }

    getOpacity() {
        return this.settings.opacity;
    }

    getBrightness() {
        return this.settings.brightness;
    }

    getLoading() {
        return this.layer?.loading ?? false;
    }

    getBoundingBox(scaleFactor: number) {
        if (!this.initialized) return null;

        return this._layerManager.getBoundingBox(this.layer, scaleFactor);
    }
}
