import { Box3, Color } from 'three';

import { type Instance } from '@giro3d/giro3d/core';
import { Extent } from '@giro3d/giro3d/core/geographic';
import { AxisGrid } from '@giro3d/giro3d/entities';
import { TickOrigin } from '@giro3d/giro3d/entities/AxisGrid';
import { genericEqualityFn } from 'components/utils';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as giro3dSlice from 'redux/giro3d';
import * as seismicGridSlice from 'redux/seismicGrid';
import { TICKS_PRESETS } from 'services/Constants';
import { useAppSelector } from 'store';
import * as datasetsSlice from 'redux/datasets';

export type Props = {
    instance: Instance;
};

/**
 * Computes the best ticks from the given spatial parameters.
 * @param floorLevel The floor elevation.
 * @param ceilingLevel The ceiling elevation.
 * @returns The computed ticks.
 */
function computeTicksFor2DView(extent: Extent, floorLevel: number, ceilingLevel: number): seismicGridSlice.Ticks {
    // We wish around 5 ticks per axis
    const hSize = 100;
    let vSize = Math.abs(ceilingLevel - floorLevel) / 5;

    // Let's find the closest tick preset that match our desired tick size
    // so that we have nice, readable numbers.
    vSize = TICKS_PRESETS.sort((a, b) => Math.abs(a - vSize) - Math.abs(b - vSize))[0];

    return {
        x: hSize,
        y: hSize,
        z: vSize,
    };
}

function SeismicGrid(props: Props) {
    const { instance } = props;

    const dispatch = useDispatch();

    const [axisGrid, setAxisGrid] = useState<AxisGrid>(
        instance.getObjects().find((o) => o instanceof AxisGrid) as AxisGrid
    );

    const volume = useAppSelector(giro3dSlice.getSeismicVolume, genericEqualityFn<Box3>);
    const project = useAppSelector(datasetsSlice.currentProject);

    const ticks = useAppSelector(seismicGridSlice.getTicks);

    const color = useAppSelector(seismicGridSlice.getColor, genericEqualityFn<Color>);
    const opacity = useAppSelector(seismicGridSlice.getOpacity);
    const visible = useAppSelector(seismicGridSlice.isVisible);
    const showLabels = useAppSelector(seismicGridSlice.getLabelVisibility);
    const zScale = useAppSelector(giro3dSlice.getZScale);

    function cleanup() {
        const entity = instance.getObjects().find((o) => o instanceof AxisGrid) as AxisGrid;
        if (entity) {
            instance.remove(entity);
            setAxisGrid(null);
        }
    }

    function updateGrid(entity: AxisGrid) {
        if (!volume || volume.isEmpty()) {
            entity.visible = false;
        } else {
            const computedFloor = volume.min.z / zScale;
            const computedCeiling = volume.max.z / zScale;

            const min = volume.min.clone();
            const max = volume.max.clone();

            // To make the grid appear in front of the seismic plane
            min.setY(-1);
            max.setY(-1);

            const actualBox = new Box3(min, max);
            const gridExtent = Extent.fromBox3(instance.referenceCrs, actualBox);

            entity.volume.extent = gridExtent;
            entity.visible = visible;
            entity.volume.ceiling = computedCeiling;
            entity.volume.floor = computedFloor;

            entity.object3d.scale.setZ(zScale);
            entity.object3d.updateMatrixWorld();
            entity.color = color;
            entity.showLabels = showLabels;

            const computeTicks = computeTicksFor2DView;
            entity.ticks = ticks ?? computeTicks(gridExtent, entity.volume.floor, entity.volume.ceiling);

            if (!ticks) dispatch(seismicGridSlice.setTicks(entity.ticks));

            entity.showCeilingGrid = false;
            entity.showSideGrids = true;
            entity.opacity = opacity;

            entity.refresh();
        }
    }

    function update() {
        if (!axisGrid) {
            const entity = new AxisGrid('axis-grid', {
                origin: TickOrigin.Relative,
                ticks,
                volume: {
                    ceiling: 0,
                    floor: 0,
                    extent: new Extent(instance.referenceCrs, 0, 1, 0, 1),
                },
            });

            instance.add(entity);
            setAxisGrid(entity);
            updateGrid(entity);
        } else updateGrid(axisGrid);

        instance.notifyChange(axisGrid);
    }

    useEffect(cleanup, [project]);

    useEffect(() => {
        update();
    }, [volume, opacity, color, visible, ticks, showLabels, zScale]);

    // This is a renderless component. We don't create any DOM element,
    // however we are still "rendering" stuff in the 3D view.
    return null;
}

export default SeismicGrid;
