import Uppy, { type UploadResult } from '@uppy/core';
import Tus from '@uppy/tus';
import { DatasetId } from 'types/common';
import DosApi from './DosApi';

// @ts-expect-error _env_ is not defined
const maxUploadFilesize = window._env_.REACT_APP_MAX_UPLOAD_FILE_SIZE
    ? // @ts-expect-error _env_ is not defined
      window._env_.REACT_APP_MAX_UPLOAD_FILE_SIZE
    : process.env.REACT_APP_MAX_UPLOAD_FILE_SIZE;

let eventBus;

const setEventBus = (e) => {
    eventBus = e;
};

const completeUploads: Record<DatasetId, UploadResult<Record<string, unknown>, Record<string, unknown>>> = {};
const uploadingInstances: Record<DatasetId, Uppy<Record<string, unknown>, Record<string, unknown>>> = {};

const newInstance = () =>
    new Uppy({
        restrictions: {
            maxFileSize: parseInt(maxUploadFilesize, 10), // bytes
        },
    })
        .use(Tus, {
            endpoint: `${DosApi.getAPIEndpoint()}/dataset/upload/`,
            chunkSize: 256 * 1024 * 1024, // bytes
            async onBeforeRequest(req) {
                // Add auth token to outgoing request
                const token = await DosApi.apiAcquireToken();
                req.setHeader('Authorization', `Bearer ${token.access_token}`);
            },
            removeFingerprintOnSuccess: true,
        })
        .on('file-added', () => eventBus.dispatch('uppy-change', { eventType: 'file-added' }))
        .on('upload', () => eventBus.dispatch('uppy-change', { eventType: 'upload' }))
        .on('progress', () => eventBus.dispatch('uppy-change', { eventType: 'progress' }))
        .on('complete', (result) => {
            const datasetId = result.successful[0].meta.datasetId as DatasetId;
            completeUploads[datasetId] = result;
            uploadingInstances[datasetId].close();
            delete uploadingInstances[datasetId];
            eventBus.dispatch('uppy-change', { eventType: 'complete' });
        });

let currentInstance = newInstance();

const getInstance = () => currentInstance;

const getUploading = () => uploadingInstances;

const getComplete = () => completeUploads;

const upload = (datasetId) => {
    currentInstance.setMeta({ datasetId });
    currentInstance.upload();
    uploadingInstances[datasetId] = currentInstance;
    currentInstance = newInstance();
    eventBus.dispatch('uppy-change', { eventType: 'uploaded' });
};

const clear = () => {
    Object.keys(completeUploads).forEach((id) => delete completeUploads[id]);
    eventBus.dispatch('uppy-change', { eventType: 'clear' });
};

const clearFiles = () => {
    currentInstance.cancelAll();
    eventBus.dispatch('uppy-change', { eventType: 'clear' });
};

export default {
    upload,
    getInstance,
    getUploading,
    getComplete,
    setEventBus,
    clear,
    clearFiles,
};
