// React
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

import { SourceFile } from 'types/SourceFile';

// Redux
import { useAppSelector } from 'store';
import { LAYER_DATA_TYPES, LAYER_STATES, SOURCE_FILE_STATES } from 'services/Constants';
import { supportedTypesLabels, supportedDataTypesLabels } from 'services/Labels';
import { useEventBus } from 'EventBus';
import { setClickedDataset, pollForDatasets, pollForSourcefiles } from 'redux/actions';
import * as layersSlice from 'redux/layers';
import * as datasetsSlice from 'redux/datasets';

// API
import DosApi from 'services/DosApi';

// Components
import ToggleSwitch from 'components/ToggleSwitch';
import useInterval from 'services/useInterval';
import { canToggleSourceFiles } from 'types/LayerState';
import { DatasetId } from 'types/common';
import ErrorBoundary from '../../../ErrorBoundary';
import SourceFileListItem from './SourceFileListItem';
import DatasetSettingsPopover from '../DatasetSettingsPopover';
import MosaicFileOutlineSetting from '../datasetSettings/MosaicSettings';

import UploadFilesModalForm from '../../forms/UploadFilesModalForm';

import SourceFileTable from '../SourceFileTable';
import MosaicOverviewTable from '../MosaicOverviewTable';
import InlineDropMenu from '../../../InlineDropMenu';
import RemoveDataset from '../RemoveDataset';
import DatasetIndicator from '../DatasetIndicator';

type Props = {
    datasetId: DatasetId;
};

const InspectDatasetMenu = (props: Props) => {
    const dispatch = useDispatch();
    const eventBus = useEventBus();

    const datasetId = props.datasetId;
    const dataset = useAppSelector(datasetsSlice.get(datasetId));
    const sourcefiles = useAppSelector(datasetsSlice.getSourceFiles(datasetId));

    const layerState = useAppSelector(layersSlice.get(datasetId));
    const isVisible = useAppSelector(layersSlice.isVisibleSelf(layerState));
    const disabled = dataset.state !== LAYER_STATES.LOADED && dataset.state !== LAYER_STATES.ACTIVE;
    const canToggleSourceFile = canToggleSourceFiles(layerState);

    const [settingsOpen, setSettingsOpen] = useState(false);

    const [manageFilesModal, setManageFilesModal] = useState(false);
    const [manageOverviewModal, setManageOverviewModal] = useState(false);
    const [uploadFilesModal, setUploadFilesModal] = useState(false);

    const toggleUploadFilesModal = () => {
        setUploadFilesModal(!uploadFilesModal);
    };
    const toggleManageFilesModal = () => {
        setManageFilesModal(!manageFilesModal);
    };
    const toggleManageOverviewModal = () => {
        setManageOverviewModal(!manageOverviewModal);
    };
    const onDatasetFileUploadClose = () => {
        toggleUploadFilesModal();
        dispatch(pollForDatasets([dataset]));
    };
    const selectDataset = () => {
        dispatch(setClickedDataset(datasetId));
        eventBus.dispatch('highlight-layer', { layer: datasetId });
    };

    const onEditSourceFile = (sourceFile: SourceFile) => {
        dispatch(setClickedDataset(datasetId, sourceFile.id));
        toggleManageFilesModal();
    };

    const setVisible = (value: boolean) => {
        dispatch(layersSlice.setVisibility({ layer: layerState, value }));
    };

    function goToLayer() {
        eventBus.dispatch('go-to-layer', { layer: layerState.datasetId });
    }

    // const formatPropertyValue = (value) => {
    //     if (!(value instanceof Object)) {
    //         return Number.isNaN(value) || Number.isInteger(value) ? value : Number(value).toFixed(2);
    //     }
    //     return 'Not shown';
    // };
    // const formatProperties = (properties) => {
    //     return (
    //         <table>
    //             {Object.entries(properties).map(([key, value]) => (
    //                 <tr key={key}>
    //                     <td>{key}</td>
    //                     <td>{formatPropertyValue(value)}</td>
    //                 </tr>
    //             ))}
    //         </table>
    //     );
    // };

    useEffect(() => {
        if (datasetId && !sourcefiles)
            DosApi.fetchDatasetSourcefiles(datasetId)
                .then((sourceFiles) => {
                    dispatch(datasetsSlice.setDatasetSourceFiles({ datasetId: dataset.id, sourceFiles }));
                })
                .catch((err) => console.log(err));
    }, [datasetId, sourcefiles]);

    useInterval(() => {
        const sourcefilesInProcess = sourcefiles.filter((s) =>
            [
                SOURCE_FILE_STATES.CONVERTING,
                SOURCE_FILE_STATES.DELETING,
                SOURCE_FILE_STATES.UPLOADING,
                SOURCE_FILE_STATES.INGESTING,
                SOURCE_FILE_STATES.VALIDATING,
            ].includes(s.state)
        );
        if (sourcefilesInProcess.length !== 0) dispatch(pollForSourcefiles(dataset.id, sourcefilesInProcess));
    }, 5000);

    if (dataset === null) return <div className="tabContent">No dataset selected</div>;

    return (
        <div className="tabContent fixedHeight">
            <div className="inspector-title">
                <span>{dataset.name}</span>
                <div className="grow" />
                <DatasetIndicator id={datasetId} dataset={dataset} />
                <InlineDropMenu id={`dataset-inspect-actions-${datasetId}`}>
                    {dataset.user_permissions.update_dataset && <RemoveDataset id={datasetId} dataset={dataset} />}
                    <Button
                        className="borderless yellow"
                        id={`dataset-inspect-goToLayer-${datasetId}`}
                        title={`${dataset.name} settings`}
                        onClick={() => {
                            goToLayer();
                        }}
                        disabled={dataset.state !== LAYER_STATES.LOADED}
                    >
                        <i className="fas fa-location-arrow" />
                    </Button>
                    <Button
                        className="borderless yellow"
                        id={`dataset-inspect-settings-${datasetId}`}
                        title={`${dataset.name} settings`}
                        onClick={() => {
                            selectDataset();
                            setSettingsOpen(true);
                        }}
                        disabled={dataset.state !== LAYER_STATES.LOADED}
                    >
                        <i className="fas fa-gear" />
                    </Button>
                </InlineDropMenu>
                <ToggleSwitch
                    id={`dataset-inspect-visible-${datasetId}`}
                    checked={isVisible}
                    onChange={(e) => setVisible(e.target.checked)}
                    disabled={disabled}
                />
            </div>
            <ErrorBoundary
                dispatch={dispatch}
                fallback={
                    <span className="error-fallback-message">
                        <i className="fal fa-exclamation-triangle icon-red" />
                        An error occured in the sourcefile list.
                        <i className="fal fa-exclamation-triangle icon-red" />
                    </span>
                }
            >
                <div className="scrollable">
                    {/* Dataset info */}
                    <div>Metadata</div>
                    <table>
                        <tbody>
                            {/* <tr>
                            <td>Owner</td>
                            <td>
                                {organizations
                                    ? organizations.find((e) => e.id === dataset.organization_id)
                                        ?.display_name
                                    : 'Loading...'}
                            </td>
                        </tr> */}
                            <tr>
                                <td>Projection</td>
                                <td colSpan={2}>EPSG:{dataset.projection}</td>
                            </tr>
                            <tr>
                                <td>Type</td>
                                <td colSpan={2}>{supportedTypesLabels[dataset.type]}</td>
                            </tr>
                            <tr>
                                <td>Data type</td>
                                <td colSpan={2}>{supportedDataTypesLabels[dataset.datatype]}</td>
                            </tr>
                            {/* {hasAttributes(layerState)
                            ? layerState.attributes.map((attribute) => (
                                  <tr key={attribute.id}>
                                      <td>{attribute.name}</td>
                                      <td>{attribute.min.toExponential(3)}</td>
                                      <td>{attribute.max.toExponential(3)}</td>
                                  </tr>
                              ))
                            : null} */}
                        </tbody>
                    </table>
                    {/* Files list */}
                    <div>
                        <div>Files</div>
                        <Button
                            className="borderless light-blue"
                            id={`dataset-inspect-upload-${datasetId}`}
                            title="Upload files"
                            onClick={toggleUploadFilesModal}
                        >
                            <i className="fas fa-upload" />
                        </Button>
                        <Button
                            className="borderless green"
                            id={`dataset-inspect-manage-${datasetId}`}
                            title="Manage files"
                            onClick={toggleManageFilesModal}
                        >
                            <i className="fas fa-pen" />
                        </Button>
                        {dataset.datatype === LAYER_DATA_TYPES.MOSAIC ? (
                            <>
                                <MosaicFileOutlineSetting dataset={dataset} />
                                <Button
                                    className="borderless yellow"
                                    id={`dataset-inspect-overviews-${datasetId}`}
                                    title="Manage overviews"
                                    onClick={toggleManageOverviewModal}
                                >
                                    <i className="fas fa-panorama" />
                                </Button>
                            </>
                        ) : null}
                    </div>
                    <ul className="list">
                        {sourcefiles?.map((sourceFile) => (
                            <SourceFileListItem
                                key={sourceFile.id}
                                sourceFile={sourceFile}
                                datasetId={datasetId}
                                onEditSourceFile={onEditSourceFile}
                                canToggleVisibility={canToggleSourceFile}
                            />
                        ))}
                        {sourcefiles?.length === 0 && 'No files yet'}
                    </ul>
                </div>
            </ErrorBoundary>
            <DatasetSettingsPopover
                dataset={dataset}
                target={`dataset-inspect-actions-${datasetId}`}
                isOpen={settingsOpen}
                onToggle={() => setSettingsOpen(false)}
            />
            <Modal className="modal-lg" isOpen={manageFilesModal} toggle={toggleManageFilesModal}>
                <SourceFileTable dataset={dataset} />
            </Modal>
            <Modal className="modal-lg" isOpen={manageOverviewModal} toggle={toggleManageOverviewModal}>
                <ModalHeader>Manage {dataset.name} overviews</ModalHeader>
                <ModalBody>
                    <MosaicOverviewTable dataset={dataset} />
                </ModalBody>
            </Modal>

            <Modal isOpen={uploadFilesModal} toggle={toggleUploadFilesModal}>
                <UploadFilesModalForm dataset={dataset} onClose={onDatasetFileUploadClose} />
            </Modal>
        </div>
    );
};

export default InspectDatasetMenu;
