import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useAppDispatch, useAppSelector } from 'store';
import { DatasetId } from 'types/common';
import { getDatasetColorHexString } from 'services/DatasetColors';
import { Vector2 } from 'three';
import TabHeader from 'components/flexLayout/TabHeader';
import HeaderButton from 'components/flexLayout/HeaderButton';
import { PopoverBody } from 'reactstrap';
import { useEffect, useState } from 'react';
import { updateElevationProfile } from 'redux/actions';
import { LAYER_DATA_TYPES, LAYER_TYPES } from 'services/Constants';
import Button from 'components/dropdown/Button';
import Slider from 'components/dropdown/Slider';
import Toggle from 'components/dropdown/Toggle';
import ElevationTooltip from './ElevationTooltip';
import * as datasetsSlice from '../../../redux/datasets';
import * as layersSlice from '../../../redux/layers';

type Props = {
    points: Vector2[];
    elevations;
    tabId: string;
};

const ElevationProfile = (props: Props) => {
    const dispatch = useAppDispatch();
    const datasets = useAppSelector(datasetsSlice.getProjectDatasets);
    const layers = useAppSelector(layersSlice.all);

    const validDatasets = datasets.filter(
        (dataset) =>
            [LAYER_TYPES.BATHYMETRY, LAYER_TYPES.HORIZON].includes(dataset.type) &&
            dataset.datatype === LAYER_DATA_TYPES.SINGLEBANDCOG &&
            layers.find((layer) => layer.datasetId === dataset.id).visible
    );

    const { points, elevations, tabId } = props;

    if (!points) return null;

    const length = Math.sqrt(
        (points[1].x - points[points.length - 1].x) ** 2 + (points[1].y - points[points.length - 1].y) ** 2
    );
    const [newSamples, setNewSamples] = useState(points.length);
    const [viewedDatasetIds, setViewedDatasetIds] = useState(Object.keys(elevations));

    useEffect(() => {
        setViewedDatasetIds(viewedDatasetIds.filter((id) => layers.find((layer) => layer.datasetId === id).visible));
    }, [layers]);

    const generateChartValues = () => {
        const sampleCount = points.length;

        const xStep = points[1].x - points[0].x;
        const yStep = points[1].y - points[0].y;
        const dStep = Math.sqrt(xStep ** 2 + yStep ** 2);

        const data = [];
        const ids = Object.keys(elevations);

        for (let i = 0; i < sampleCount; i++) {
            data[i] = {
                x: points[i].x,
                y: points[i].y,
                d: dStep * i,
            };
            ids.forEach((id) => {
                data[i][id] = elevations[id][i];
            });
        }

        return data;
    };

    const ids = Object.keys(elevations);

    const data = generateChartValues();
    const datasetName: Record<DatasetId, string> = {};
    ids.forEach((key) => {
        datasetName[key] = datasets.find((dataset) => dataset.id === key).name;
    });

    return (
        <>
            <TabHeader
                left={
                    <>
                        <HeaderButton
                            popover={{
                                name: 'Datasets',
                                icon: 'fas fa-layer-group',
                                content: (
                                    <PopoverBody>
                                        <ul>
                                            {validDatasets.map((dataset) => (
                                                <Toggle
                                                    key={dataset.id}
                                                    title={dataset.name}
                                                    checked={viewedDatasetIds.includes(dataset.id)}
                                                    onChange={(v) =>
                                                        setViewedDatasetIds(
                                                            v
                                                                ? [...viewedDatasetIds, dataset.id]
                                                                : viewedDatasetIds.filter((id) => id !== dataset.id)
                                                        )
                                                    }
                                                />
                                                // <label key={dataset.id} htmlFor={dataset.id}>
                                                //     <ToggleSwitch
                                                //         id={dataset.id}

                                                //     />
                                                //     {viewedDatasetIds.includes(dataset.id) &&
                                                //     ![...elevationMaps.keys()].includes(dataset.id) ? (
                                                //         <i className="fas fa-circle-dashed" />
                                                //     ) : null}
                                                // </label>
                                            ))}
                                            <Button
                                                title="Recalculate"
                                                icon="fas fa-calculator"
                                                onClick={() => {
                                                    dispatch(
                                                        updateElevationProfile(
                                                            points[0].toArray(),
                                                            points[points.length - 1].toArray(),
                                                            validDatasets.filter((d) =>
                                                                viewedDatasetIds.includes(d.id)
                                                            ),
                                                            newSamples,
                                                            tabId
                                                        )
                                                    );
                                                }}
                                            />
                                        </ul>
                                    </PopoverBody>
                                ),
                            }}
                            key="datasets"
                        />
                        <HeaderButton
                            popover={{
                                name: 'Resolution & Samples',
                                icon: 'fas fa-ruler-horizontal',
                                content: (
                                    <PopoverBody>
                                        <ul>
                                            <Slider
                                                title="Samples"
                                                value={newSamples}
                                                min={10}
                                                max={1000}
                                                step={10}
                                                onChange={(v) => setNewSamples(v)}
                                            />
                                            <Slider
                                                title="Resolution"
                                                value={Number.parseFloat((length / newSamples).toPrecision(3))}
                                                min={length / 1000}
                                                max={length / 10}
                                                step={0.1}
                                                onChange={(v) => setNewSamples(Math.round(length / v))}
                                            />
                                            <Button
                                                title="Recalculate"
                                                icon="fas fa-calculator"
                                                onClick={() =>
                                                    dispatch(
                                                        updateElevationProfile(
                                                            points[0].toArray(),
                                                            points[points.length - 1].toArray(),
                                                            validDatasets.filter((d) =>
                                                                viewedDatasetIds.includes(d.id)
                                                            ),
                                                            newSamples,
                                                            tabId
                                                        )
                                                    )
                                                }
                                            />
                                        </ul>
                                    </PopoverBody>
                                ),
                            }}
                            key="samples"
                        />
                    </>
                }
                center={undefined}
                right={undefined}
            />
            <ResponsiveContainer width="100%" height="100%" className="tabContent">
                <LineChart
                    margin={{
                        top: 8,
                        right: 8,
                        bottom: 0,
                        left: -24,
                    }}
                    data={data}
                >
                    <CartesianGrid stroke="var(--light-gray-4)" />
                    <XAxis
                        type="number"
                        dataKey="d"
                        name="distance"
                        unit="m"
                        stroke="var(--light-gray-4)"
                        domain={[(dataMin: number) => Math.floor(dataMin), (dataMax: number) => Math.ceil(dataMax)]}
                        tick={{ fontSize: '0.75rem' }}
                        tickCount={30}
                        interval={2}
                    />
                    <YAxis
                        type="number"
                        name="elevation"
                        stroke="var(--light-gray-4)"
                        domain={[(dataMin: number) => Math.floor(dataMin), (dataMax: number) => Math.ceil(dataMax)]}
                        tick={{ fontSize: '0.75rem' }}
                        tickCount={20}
                        interval={2}
                    />
                    <Tooltip
                        cursor={{ strokeDasharray: '3 3' }}
                        isAnimationActive={false}
                        content={<ElevationTooltip lookUp={datasetName} />}
                    />
                    {ids
                        .filter((id) => viewedDatasetIds.includes(id))
                        .map((key) => (
                            <Line
                                key={key}
                                dataKey={key}
                                stroke={`#${getDatasetColorHexString(key)}`}
                                strokeWidth={2}
                                dot={false}
                                isAnimationActive={false}
                            />
                        ))}
                </LineChart>
            </ResponsiveContainer>
        </>
    );
};
export default ElevationProfile;
