import * as THREE from 'three';
import { GeoJSON as GeoJSONFormat } from 'ol/format';
import Giro3dMap from '@giro3d/giro3d/entities/Map';
import Layer, { LayerEvents } from '@giro3d/giro3d/core/layer/Layer';
import ElevationLayer from '@giro3d/giro3d/core/layer/ElevationLayer';
import MaskLayer, { MaskMode } from '@giro3d/giro3d/core/layer/MaskLayer';
import type Instance from '@giro3d/giro3d/core/Instance';
import ColorLayer from '@giro3d/giro3d/core/layer/ColorLayer';
import VectorSource from '@giro3d/giro3d/sources/VectorSource';
import { Fill, Style } from 'ol/style';
import type { ImageSource } from '@giro3d/giro3d/sources';
import type { Plane } from 'three';
import { type ElevationRange } from '@giro3d/giro3d/core';
import type {
    DatasetId,
    GeometryWithCRS,
    ScopeColorLayer,
    ScopeElevationLayer,
    ScopeLayerUserData,
    SourceFileId,
    UUID,
} from 'types/common';
import { Coordinates, Extent } from '@giro3d/giro3d/core/geographic';
import TileMesh from '@giro3d/giro3d/core/TileMesh';
import { isGiro3dMaskLayer, isGiro3dVectorSource } from 'services/BaseGiro3dService';
import { Feature } from 'ol';
import TileIndex from '@giro3d/giro3d/core/TileIndex';
import FootprintManager, { AddFootprintOptions, PickedFootprint, UpdateFootprintOptions } from './FootprintManager';

const FLAT_MAP_SEGMENTS = 4;

/**
 * Draping modes for color layers.
 */
enum DrapingMode {
    /**
     * The color layer is draped onto an elevation dataset.
     */
    Draped = 1,

    /**
     * The color layer is displayed undraped, on a flat plane.
     */
    Undraped = 2,
}

class ContourLineParams {
    enabled: boolean;

    primaryInterval: number;

    secondaryInterval: number;

    opacity: number;

    color: THREE.Color;

    constructor({ enabled, primary, secondary, color, opacity }) {
        this.enabled = enabled;
        this.primaryInterval = primary;
        this.secondaryInterval = secondary;
        this.opacity = opacity;
        this.color = color;
    }
}

class HillshadeParams {
    enabled: boolean;

    intensity: number;

    azimuth: number;

    zenith: number;

    constructor({ enabled, intensity, azimuth, zenith }) {
        this.enabled = enabled;
        this.intensity = intensity;
        this.azimuth = azimuth;
        this.zenith = zenith;
    }
}

/**
 * The `LayerManager` handles the lifecycle of map-related layers, and all rules associated with them.
 *
 * SCOPE does not have the notion of *maps* (this is a Giro3D concept). The mapping between SCOPE
 * datasets and Giro3D layers is done entirely in the `LayerManager`, to ensure consistent behaviour.
 *
 * ## Draping modes
 *
 * From the business logic perspective, a color layer may be represented in two modes: *draped* and *undraped*.
 *
 * - **Draped** layers are applied onto regular Giro3D maps that also display elevation data and
 * optional color data. Those maps support hillshading and have variable geometric resolution.
 *
 * - **Undraped** layers are hosted on their own, planar Giro3D maps (with no elevation layer).
 * Those maps, being flat, do not support hillshading and have a fixed geometrix resolution (because
 * changing the geometric resolution on a planar map is useless).
 *
 * ## Rules
 *
 * - Elevation layers are necessarily part of a regular map, but color layers can be either draped
 * onto a bathymetry map, or hosted on their own, flat map.
 *
 * - Changing the rendering parameters of an elevation layer is actually changing the visibility/opacity
 * of its map (meaning that color layers hosted on the map are affected as well).
 *
 * - Changing the rendering parameters of a color layer (visibility, opacity, color map) will update
 * all maps that host this color layer (whether regular or flat maps). Note: elevation layers
 * have no notion of opacity.
 *
 * - Changing the mode of a color layer from draped to undraped means that the layer will be removed
 * from all regular maps and be moved into a separate, dedicated map. Conversely, draping a layer
 * means deleting its own flat map, and putting it back to regular maps.
 */
class LayerManager {
    private readonly _instance: Instance;
    private readonly _flatMaps: Map<ScopeColorLayer, Giro3dMap>;
    private readonly _regularMaps: Map<ScopeElevationLayer, Giro3dMap>;
    private readonly _maskLayers: Map<ScopeElevationLayer, { maskLayer: MaskLayer; receivers: Set<string> }>;
    private readonly _colorLayers: Map<string, { layer: ScopeColorLayer; disposeTimeout?: NodeJS.Timeout }>;
    private readonly _hillshadingParams: HillshadeParams;
    private readonly _contourLineParams: ContourLineParams;
    private readonly _onVolumeChanged: () => void;
    private readonly _root: THREE.Group<THREE.Object3DEventMap>;
    private readonly _projectExtent: Extent;
    private readonly _footprintManager: FootprintManager;

    private _layerOrdering: Array<string>;
    private _segments: number;
    private _zScale = 1;

    /**
     * @param props
     * @param props.instance The Giro3D instance.
     * @param props.segments The geometric resolution of regular maps.
     * @param props.hillshading Is hillshading enabled ?
     * @param props.hillshadingIntensity The hillshading intensity.
     * @param props.azimuth The light direction azimuth.
     * @param props.zenith The light direction zenith.
     * @param props.contourLines Are contour lines enabled ?
     * @param props.contourLinePrimaryInterval Contour line primary interval, in meters
     * @param props.contourLineSecondaryInterval Contour line primary interval, in meters
     * @param props.contourLineColor Contour line color, in hex
     * @param props.contourLineOpacity Contour line color, in hex
     * @param props.onVolumeChanged The callback to call when the overall volume of the
     * maps changed (map created, removed, or moved on its Z-axis).
     */
    constructor({
        instance,
        segments,
        hillshading,
        hillshadingIntensity,
        azimuth,
        zenith,
        contourLines,
        contourLinePrimaryInterval,
        contourLineSecondaryInterval,
        contourLineColor,
        contourLineOpacity,
        projectExtent,
        onVolumeChanged,
    }: {
        instance: Instance;
        segments: number;
        hillshading: boolean;
        hillshadingIntensity: number;
        azimuth: number;
        zenith: number;
        contourLines?: boolean;
        contourLinePrimaryInterval?: number;
        contourLineSecondaryInterval?: number;
        contourLineColor?: THREE.Color;
        contourLineOpacity?: number;
        projectExtent?: Extent;
        onVolumeChanged?: () => void;
    }) {
        this._instance = instance;

        this._flatMaps = new Map();
        this._regularMaps = new Map();
        this._maskLayers = new Map();
        this._colorLayers = new Map();
        this._projectExtent = projectExtent;

        /**
         * List of layers' id from back to front
         */
        this._layerOrdering = [];

        this._segments = segments;

        this._hillshadingParams = new HillshadeParams({
            enabled: hillshading,
            azimuth,
            zenith,
            intensity: hillshadingIntensity,
        });

        this._contourLineParams = new ContourLineParams({
            enabled: contourLines ?? false,
            primary: contourLinePrimaryInterval ?? 0,
            secondary: contourLineSecondaryInterval ?? 0,
            opacity: contourLineOpacity ?? 1,
            color: new THREE.Color(contourLineColor ?? 'black'),
        });

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this._onVolumeChanged = onVolumeChanged ?? (() => {});

        this._root = new THREE.Group();
        this._root.name = 'LayerManager-root';

        this._instance.scene.add(this._root);

        if (projectExtent) {
            this._footprintManager = new FootprintManager(this._instance, projectExtent);
        }
    }

    /**
     * Sets the segments property of all regular maps.
     * @param v The new segment value.
     */
    set segments(v: number) {
        this._segments = v;
        for (const map of this._regularMaps.values()) {
            map.segments = v;
            this.notify(map);
        }
    }

    /**
     * Sets the contour line property of all regular maps.
     * @param v The new value.
     */
    set contourLines(v: boolean) {
        this._contourLineParams.enabled = v;
        for (const map of this._regularMaps.values()) {
            map.materialOptions.contourLines.enabled = v;
            this.notify(map);
        }
    }

    /**
     * Sets the contour line primary interval of all regular maps.
     * @param v The new value.
     */
    set contourLinePrimaryInterval(v: number) {
        this._contourLineParams.primaryInterval = v;
        for (const map of this._regularMaps.values()) {
            map.materialOptions.contourLines.interval = v;
            this.notify(map);
        }
    }

    /**
     * Sets the contour line secondary interval of all regular maps.
     * @param v The new value.
     */
    set contourLineSecondaryInterval(v: number) {
        this._contourLineParams.secondaryInterval = v;
        for (const map of this._regularMaps.values()) {
            map.materialOptions.contourLines.secondaryInterval = v;
            this.notify(map);
        }
    }

    /**
     * Sets the contour line opacity of all regular maps.
     * @param v The new value.
     */
    set contourLineOpacity(v: number) {
        this._contourLineParams.opacity = v;
        for (const map of this._regularMaps.values()) {
            map.materialOptions.contourLines.opacity = v;
            this.notify(map);
        }
    }

    /**
     * Sets the contour line color of all regular maps.
     * @param v The new color, in hex.
     */
    set contourLineColor(v: THREE.Color) {
        this._contourLineParams.color = v;
        for (const map of this._regularMaps.values()) {
            map.materialOptions.contourLines.color = v;
            this.notify(map);
        }
    }

    /**
     * Sets the hillshading property of all regular maps.
     * @param v The new value.
     */
    set hillshading(v: boolean) {
        this._hillshadingParams.enabled = v;
        for (const map of this._regularMaps.values()) {
            map.materialOptions.hillshading.enabled = v;
            this.notify(map);
        }
    }

    /**
     * Sets the hillshading intensity of all regular maps.
     * @param {number} v The new value.
     */
    set hillshadingIntensity(v: number) {
        this._hillshadingParams.intensity = v;

        this.updateHillshadingIntensity();
    }

    private updateHillshadingIntensity() {
        for (const map of this._regularMaps.values()) {
            map.materialOptions.hillshading.intensity = this._hillshadingParams.intensity;
            map.materialOptions.hillshading.zFactor = this._zScale;
            this.notify(map);
        }
    }

    /**
     * Sets the light azimuth of all regular maps.
     * @param {number} v The new azimuth value.
     */
    set azimuth(v: number) {
        this._hillshadingParams.azimuth = v;

        for (const map of this._regularMaps.values()) {
            map.materialOptions.hillshading.azimuth = v;
            this.notify(map);
        }
    }

    /**
     * Sets the light zenith of all regular maps.
     * @param {number} v The new zenith value.
     */
    set zenith(v: number) {
        this._hillshadingParams.zenith = v;

        for (const map of this._regularMaps.values()) {
            map.materialOptions.hillshading.zenith = v;
            this.notify(map);
        }
    }

    /**
     * Adds a layer on all maps that also host the associated layer.
     * @param layer
     * @param associatedLayer
     */
    async addLayerOnMapsContaining(layer: Layer, associatedLayer: ScopeColorLayer | ScopeElevationLayer) {
        if (associatedLayer instanceof ElevationLayer) {
            const map = this.getRegularMap(associatedLayer);

            return map.addLayer(layer);
        }

        const promises = [];
        const maps = this.getMapsContaining(associatedLayer);
        for (const map of maps) {
            promises.push(map.addLayer(layer));
        }

        return Promise.allSettled(promises);
    }

    /**
     * Adds a layer to the map manager.
     * @param {Layer} layer The Giro3D layer to add.
     * @returns {Promise<Layer>} A promise that resolves when the layer has been added.
     */
    async addLayer(layer: Layer): Promise<Layer> {
        if (!(layer instanceof Layer)) {
            throw new Error('not a Giro3D layer');
        }

        if (layer instanceof ElevationLayer) {
            await this.addElevationLayer(layer);
        } else if (layer instanceof ColorLayer) {
            this._colorLayers.set(layer.id, { layer });
            await this.addColorLayerToRegularMaps(layer);
        }

        this.doReorderLayers();

        return layer;
    }

    /**
     * Removes a layer to the map manager.
     * @param layer The Giro3D layer to add.
     */
    removeLayer(layer: Layer) {
        if (!(layer instanceof Layer)) {
            throw new Error('not a Giro3D layer');
        }

        if (layer instanceof ElevationLayer) {
            this.removeElevationLayer(layer);
        } else if (layer instanceof ColorLayer) {
            this.removeColorLayerFromAllMaps(layer);
            this._colorLayers.delete(layer.id);
        }

        // release unmanaged memory of this layer (such as textures).
        // the layer will not be usable again.
        layer.dispose();
    }

    async reorderLayers(order: DatasetId[]) {
        this._layerOrdering = order;

        return this.doReorderLayers();
    }

    private async doReorderLayers() {
        if (this._colorLayers.size === 0) {
            return;
        }
        const newOrder: string[] = [];

        // Ensure the footprint layer is always on bottom
        if (this._footprintManager) {
            newOrder.push(this._footprintManager.layer.name);
        }
        newOrder.push(...this._layerOrdering);

        const promises = [];

        for (const [id, colorLayer] of this._colorLayers) {
            const flatMap = this._flatMaps.get(colorLayer.layer);
            if (flatMap) continue;

            const elevationOrder = newOrder.indexOf(id);
            const included = newOrder.slice(0, elevationOrder);
            const ignored = newOrder.slice(elevationOrder);

            for (const [elevationLayer, regularMap] of this._regularMaps) {
                if (included.indexOf(elevationLayer.userData.datasetId) !== -1) {
                    if (regularMap.getLayers((l) => l.userData.datasetId === id).length === 0) {
                        promises.push(regularMap.addLayer(colorLayer.layer));
                    }
                } else if (ignored.indexOf(elevationLayer.userData.datasetId) !== -1) {
                    if (regularMap.getLayers((l) => l.userData.datasetId === id).length > 0) {
                        regularMap.removeLayer(colorLayer.layer);
                    }
                }
            }
        }

        await Promise.allSettled(promises);

        const footprintLayer = this._footprintManager?.layer;

        for (const [, regularMap] of this._regularMaps) {
            regularMap.sortColorLayers((a: ScopeColorLayer, b: ScopeColorLayer) => {
                if (a === footprintLayer) {
                    return -1;
                }
                if (b === footprintLayer) {
                    return 1;
                }
                const idxA = newOrder.indexOf(a.userData.datasetId);
                const idxB = newOrder.indexOf(b.userData.datasetId);
                return (idxA !== -1 ? idxA : 0) - (idxB !== -1 ? idxB : 0);
            });
            this.notify(regularMap);
        }
    }

    /**
     * Gets the visibility of a layer.
     * @param layer The layer.
     * @returns The layer visibility.
     */
    getVisibility(layer: Layer): boolean {
        if (layer instanceof ElevationLayer) {
            const map = this._regularMaps.get(layer);
            return map.visible;
        }

        return layer.visible;
    }

    /**
     * @param maskLayer The mask layer.
     * @param visible The new visibility.
     */
    private updateMaskVisibility(maskLayer: MaskLayer, visible: boolean) {
        maskLayer.visible = visible;

        // Notify maps that have this layer as a mask
        this._regularMaps.forEach((maskedMap) => {
            if (maskedMap.getColorLayers().includes(maskLayer)) {
                this.notify(maskedMap);
            }
        });
    }

    /**
     * Sets the visibility of a layer.
     * @param layer The layer.
     * @param visible The new state.
     */
    setVisibility(layer: Layer, visible: boolean) {
        if (layer instanceof ElevationLayer) {
            const map = this._regularMaps.get(layer);
            map.visible = visible;
            this.notify(map);
            const tuple = this._maskLayers.get(layer);
            if (tuple) {
                this.updateMaskVisibility(tuple.maskLayer, visible);
            }
        } else if (layer instanceof ColorLayer) {
            if (this._flatMaps.has(layer)) {
                const map = this._flatMaps.get(layer);
                map.visible = visible;
                this.notify(map);
            } else {
                layer.visible = visible;
                if (!visible) {
                    // To free memory, let's dispose the layer after 5 seconds of non-visible state
                    const timeout = setTimeout(() => layer.dispose(), 5000);
                    this._colorLayers.get(layer.id).disposeTimeout = timeout;
                } else {
                    const timeout = this._colorLayers.get(layer.id).disposeTimeout;
                    clearTimeout(timeout);
                }

                for (const map of this._regularMaps.values()) {
                    this.notify(map);
                }
            }
        }
    }

    /**
     *
     * @param elevationLayer The layer to update.
     * @param color The new color.
     */
    setBackgroundColor(elevationLayer: ScopeElevationLayer, color: THREE.Color) {
        const map = this.getRegularMap(elevationLayer);

        // Giro3D's elevation layer have no notion of background color, however the maps do.
        map.materialOptions.backgroundColor = color;
        this.notify(map);
    }

    /**
     * Gets the background color of the elevation layer.
     * @param elevationLayer
     * @return The background color.
     */
    getBackgroundColor(elevationLayer: ScopeElevationLayer): THREE.Color {
        const map = this.getRegularMap(elevationLayer);

        return map.materialOptions.backgroundColor;
    }

    /**
     * Sets the opacity of the layer.
     * @param layer The layer to update.
     * @param value The new opacity.
     */
    setOpacity(layer: Layer, value: number) {
        if (layer instanceof ElevationLayer) {
            const map = this.getRegularMap(layer);

            // Elevation layers have no opacity : we update the map's opacity instead.
            map.opacity = value;
            this.notify(map);
        } else if (layer instanceof ColorLayer) {
            if (this._flatMaps.has(layer)) {
                // Undraped layers have their own map : update the map's opacity instead.
                const map = this._flatMaps.get(layer);
                map.opacity = value;
                this.notify(map);
            } else {
                // Draped layers have their own opacity.
                layer.opacity = value;

                for (const map of this._regularMaps.values()) {
                    this.notify(map);
                }
            }
        }
    }

    setFootprintBrightness(sourceFileId: SourceFileId, brightness: number) {
        this._footprintManager?.setBrightness(sourceFileId, brightness);
    }

    /**
     * Sets the brightness of the layer.
     * @param layer The layer to update.
     * @param value The new brightness.
     */
    setBrightness(layer: Layer, value: number) {
        if (layer instanceof ElevationLayer) {
            const map = this.getRegularMap(layer);
            map.materialOptions.colorimetry.brightness = value;
            this.notify(map);
        } else if (layer instanceof ColorLayer) {
            layer.brightness = value;
            const flatMap = this._flatMaps.get(layer);
            this.notify(flatMap);
            for (const map of this._regularMaps.values()) {
                this.notify(map);
            }
        }
    }

    /**
     * Changes the vertical position of an undraped layer.
     * @param colorLayer The layer to update.
     * @param z The height, in meters.
     */
    setUndrapedLayerHeight(colorLayer: ScopeColorLayer, z: number) {
        const map = this._flatMaps.get(colorLayer);
        if (!map) {
            throw new Error('layer is not undraped');
        }

        map.object3d.position.setZ(z);
        map.object3d.updateMatrixWorld();
        this.notify(map);

        this._onVolumeChanged();
    }

    /**
     * Changes the draping mode for the color layer.
     * @param colorLayer The layer to update.
     * @param newMode The draping mode.
     */
    setDrapingMode(colorLayer: ScopeColorLayer, newMode: DrapingMode) {
        if (!this._colorLayers.has(colorLayer.id)) {
            throw new Error('unknown color layer');
        }

        let flatMap: Giro3dMap;

        // Rendering parameters are handled differently whether the layer is draped or not.
        const renderingParams = this.getRenderingParameters(colorLayer);

        switch (newMode) {
            case DrapingMode.Undraped:
                if (!this._flatMaps.has(colorLayer)) {
                    this.removeColorLayerFromAllMaps(colorLayer);
                    flatMap = this.createFlatMap(colorLayer);
                    flatMap.addLayer(colorLayer);
                    this._flatMaps.set(colorLayer, flatMap);
                }
                break;
            default:
                flatMap = this._flatMaps.get(colorLayer);
                if (flatMap) {
                    flatMap.removeLayer(colorLayer);
                    this._instance.remove(flatMap);
                    this._flatMaps.delete(colorLayer);
                    this.addColorLayerToRegularMaps(colorLayer);
                }
                break;
        }

        this.setRenderingParameters(colorLayer, renderingParams);
        this.doReorderLayers();

        this._onVolumeChanged();
    }

    /**
     * Sets the clipping range for the color layer.
     * @param layer The layer to update.
     * @param range The range to update, or null if clipping range must be disabled.
     */
    setClippingRange(layer: ScopeColorLayer, range?: ElevationRange) {
        if (!this._colorLayers.has(layer.id)) {
            throw new Error('unknown color layer');
        }

        const newRange = range != null ? { min: range.min, max: range.max } : undefined;

        layer.elevationRange = newRange;

        for (const map of this.getMapsContaining(layer)) {
            this.notify(map);
        }
    }

    /**
     * Returns the layer corresponding to the ID
     * @param layerId Layer ID
     * @returns Layer if found, or undefined
     */
    private getLayerById(layerId: string): Layer | undefined {
        return (
            this._colorLayers.get(layerId)?.layer ||
            Array.from(this._flatMaps.keys()).find((l) => l.id === layerId) ||
            Array.from(this._regularMaps.keys()).find((l) => l.id === layerId)
        );
    }

    /**
     * Returns the layer corresponding to the Name
     * @param layerName Layer Name
     * @returns Layer if found, or undefined
     */
    private getLayerByName(layerName: string): Layer | undefined {
        return (
            this._colorLayers.get(layerName)?.layer ||
            Array.from(this._flatMaps.keys()).find((l) => l.name === layerName) ||
            Array.from(this._regularMaps.keys()).find((l) => l.name === layerName)
        );
    }

    /**
     * Returns an array of maps that host this layer.
     * @param layer Layer or Layer ID
     * @returns An array of maps, possibly empty.
     */
    getMaps(layer: Layer | string): Array<Giro3dMap> {
        if (typeof layer === 'string' || layer instanceof String) {
            const layerId = layer;
            layer = this.getLayerById(layerId as string);
            if (layer === undefined) {
                throw new Error(`unknown layer id: ${layerId}`);
            }
        }

        if (layer instanceof ElevationLayer) {
            if (this._regularMaps.has(layer)) {
                return [this._regularMaps.get(layer)];
            }

            return [];
        }

        return this.getMapsContaining(layer as ScopeColorLayer);
    }

    /**
     * Sets the z-scale of the maps.
     * @param value The new z-scale.
     */
    setZScale(value: number) {
        this._zScale = value;
        this._root.scale.setZ(value);
        this._root.updateMatrixWorld(true);
        this.updateHillshadingIntensity();
    }

    /**
     * Computes the bounding box of the layer.
     * @param layer The layer
     * @param zScale The z-scale.
     * @returns The bounding box.
     */
    getBoundingBox(layer: Layer, zScale: number): THREE.Box3 {
        const extent = layer.getExtent();

        if (extent == null) {
            return null;
        }
        const result = new THREE.Box3();

        if (layer instanceof ElevationLayer) {
            const map = this._regularMaps.get(layer);
            if (!map) {
                throw new Error('unknown layer');
            }
            const minmax = layer.minmax || { min: 0, max: 0 };
            result.min.set(extent.west(), extent.south(), minmax.min * zScale);
            result.max.set(extent.east(), extent.north(), minmax.max * zScale);
        } else if (layer instanceof ColorLayer) {
            if (!this._colorLayers.has(layer.id)) {
                throw new Error('unknown color layer');
            }

            if (this._flatMaps.has(layer)) {
                const map = this._flatMaps.get(layer);

                // Flat maps can be moved on their Z-axis, so we must take that into account.
                const z = map.object3d.position.z * zScale;

                result.min.set(extent.west(), extent.south(), z);
                result.max.set(extent.east(), extent.north(), z);
            } else {
                let minHeight = +Infinity;
                let maxHeight = -Infinity;

                // Let's take the min/max of all regular maps that host our color layer.
                for (const map of this.getMapsContaining(layer)) {
                    const { min, max } = map.getElevationMinMax();

                    minHeight = Math.min(min, minHeight);
                    maxHeight = Math.max(max, maxHeight);
                }

                minHeight *= zScale;
                maxHeight *= zScale;

                result.min.set(extent.west(), extent.south(), minHeight);
                result.max.set(extent.east(), extent.north(), maxHeight);
            }
        }

        return result;
    }

    /**
     * Computes the min and max of the visible regular maps.
     * @param zScale The z-scale.
     * @returns The minmax.
     */
    getMapsMinMax(zScale: number): { min: number; max: number } {
        let minHeight = +Infinity;
        let maxHeight = -Infinity;

        // Let's take the min/max of all regular maps that are visible.
        for (const map of this._regularMaps.values()) {
            if (!map.visible) continue;
            const { min, max } = map.getElevationMinMax();

            minHeight = Math.min(min, minHeight);
            maxHeight = Math.max(max, maxHeight);
        }

        minHeight *= zScale;
        maxHeight *= zScale;

        return { min: minHeight, max: maxHeight };
    }

    /**
     * Gets the rendering parameters of the layer.
     * @param layer The layer.
     * @returns The rendering parameters.
     */
    private getRenderingParameters(layer: Layer): { visible: boolean; opacity: number } {
        let visible: boolean;
        let opacity: number;

        let map: Giro3dMap;
        if (layer instanceof ElevationLayer) {
            map = this.getRegularMap(layer);
        } else if (this._flatMaps.has(layer as ScopeColorLayer)) {
            map = this._flatMaps.get(layer as ScopeColorLayer);
        }

        // Layers with their own map use the map's rendering parameters instead of the layer's.
        if (map) {
            visible = map.visible;
            opacity = map.opacity;
        } else {
            visible = layer.visible;
            opacity = (layer as ColorLayer).opacity;
        }

        return { visible, opacity };
    }

    private setRenderingParameters(layer: Layer, { visible, opacity }) {
        let ownMap: Giro3dMap;
        if (layer instanceof ElevationLayer) {
            ownMap = this.getRegularMap(layer);
        } else if (layer instanceof ColorLayer && this._flatMaps.has(layer)) {
            ownMap = this._flatMaps.get(layer);
        }

        if (ownMap) {
            ownMap.visible = visible;
            ownMap.opacity = opacity;

            if (layer instanceof ColorLayer) {
                layer.opacity = 1;
            }
            layer.visible = true;
            this.notify(ownMap);
        } else {
            layer.visible = visible;
            if (layer instanceof ColorLayer) {
                layer.opacity = opacity;
                for (const map of this.getMapsContaining(layer)) {
                    this.notify(map);
                }
            }
        }
    }

    /**
     * Returns the regular map that hosts the specified elevation layer.
     * @param elevationLayer The layer.
     * @returns The map.
     * @throws {Error} If the map is not found.
     */
    private getRegularMap(elevationLayer: ScopeElevationLayer): Giro3dMap {
        const map = this._regularMaps.get(elevationLayer);
        if (!map) {
            throw new Error('unknown layer');
        }

        return map;
    }

    private notify(map: Giro3dMap) {
        this._instance.notifyChange(map);
    }

    /**
     * Notifies the Giro3D instance that the layer has changed.
     * @param layer
     */
    notifyChange(layer: Layer) {
        if (layer instanceof ElevationLayer) {
            this.notify(this.getRegularMap(layer));
        } else {
            for (const map of this.getMapsContaining(layer as ScopeColorLayer)) {
                this.notify(map);
            }
        }
    }

    /**
     * Returns an array of maps that contain this layer.
     * @param {ColorLayer} layer The layer.
     * @returns {Array<Giro3dMap>} An array of maps, possibly empty.
     */
    private getMapsContaining(layer: ScopeColorLayer): Array<Giro3dMap> {
        if (this._flatMaps.has(layer)) {
            return [this._flatMaps.get(layer)];
        }

        const result = [];
        for (const map of this._regularMaps.values()) {
            if (map.getLayers((l) => l.id === layer.id).length > 0) {
                result.push(map);
            }
        }

        return result;
    }

    /**
     * @param layer The layer to add.
     * @returns A promise that resolve when the color layer has been added.
     */
    private async addColorLayerToRegularMaps(layer: ColorLayer): Promise<void> {
        if (this._regularMaps.size === 0) {
            return;
        }

        const promises: Array<Promise<unknown>> = [];

        // Distribute this color layer to all existing regular maps.
        for (const map of this._regularMaps.values()) {
            // Ensure that the layer is not added twice to the map
            if (map.getColorLayers().includes(layer)) {
                continue;
            }
            const promise = map.addLayer(layer).then(() => this.notify(map));
            promises.push(promise);
        }

        await Promise.allSettled(promises);
    }

    /**
     * @param elevationLayer The layer to add.
     * @returns A promise that resolve when the layer has been added.
     */
    private async addElevationLayer(elevationLayer: ScopeElevationLayer): Promise<void> {
        if (this._regularMaps.has(elevationLayer)) {
            return;
        }

        // An elevation layer is necessarily hosted on a draped map.
        const map = await this.createRegularMap(elevationLayer);

        this._regularMaps.set(elevationLayer, map);

        const promises: Array<Promise<unknown>> = [];

        promises.push(map.addLayer(elevationLayer));

        // Distribute all existing color layers to this map as well
        for (const colorLayer of this._colorLayers.values()) {
            promises.push(map.addLayer(colorLayer.layer));
        }

        await Promise.allSettled(promises);

        this._onVolumeChanged();
        this.updateHillshadingIntensity();

        this.notify(map);
        this._root.updateMatrixWorld(true);
    }

    async addFootprint(options: AddFootprintOptions) {
        this._footprintManager?.addFootprint(options);
    }

    updateFootprint(options: UpdateFootprintOptions) {
        this._footprintManager?.updateFootprint(options);
    }

    removeFootprint(id: UUID) {
        this._footprintManager?.removeFootprint(id);
    }

    pickFootprints(coord: Coordinates): PickedFootprint[] {
        return this._footprintManager?.pick(coord);
    }

    getVectorAtCoordinate(
        map: Giro3dMap,
        coordinate: Coordinates,
        hitTolerance = 0,
        tileHint: TileMesh = undefined,
        target = []
    ): Array<{ layer: Layer<LayerEvents, ScopeLayerUserData>; feature: Feature }> {
        if (hitTolerance === 0) {
            map.forEachLayer((layer) => {
                if (
                    layer !== this._footprintManager?.layer &&
                    !isGiro3dMaskLayer(layer) &&
                    isGiro3dVectorSource(layer.source)
                ) {
                    const coordinateLayer = coordinate.as(layer.extent.crs());
                    const coord = [coordinateLayer.x, coordinateLayer.y];
                    for (const feature of layer.source.source.getFeaturesAtCoordinate(coord)) {
                        target.push({ layer, feature });
                    }
                }
            });
        } else {
            let tile = tileHint;
            if (!tile) {
                tile = map.tileIndex.tiles.get(TileIndex.getKey(0, 0, 0)).deref() as TileMesh;
                for (const t of map.tileIndex.tiles) {
                    const n = t[1].deref() as TileMesh;
                    if (n && n.material && n.material.visible && n.extent.isPointInside(coordinate)) {
                        tile = n;
                        break;
                    }
                }
            }
            const tileLayer = tile.extent.as(coordinate.crs);

            const tileExtent = tileLayer.dimensions();
            const imageSize = map.imageSize;
            const xRes = tileExtent.x / imageSize.x;
            const yRes = tileExtent.y / imageSize.y;
            const hitToleranceSqr = hitTolerance ** 2;

            const e = new Extent(
                coordinate.crs,
                coordinate.x - xRes * hitTolerance,
                coordinate.x + xRes * hitTolerance,
                coordinate.y - yRes * hitTolerance,
                coordinate.y + yRes * hitTolerance
            );

            const features = this.getVectorInExtent(map, e);
            for (const feat of features) {
                const coordinateLayer = coordinate.as(feat.layer.extent.crs());
                const coord = [coordinateLayer.x, coordinateLayer.y];
                if (feat.feature.getGeometry().intersectsCoordinate(coord)) {
                    target.push(feat);
                    continue;
                }

                const closestPoint = feat.feature.getGeometry().getClosestPoint(coord);
                const distX = Math.abs(closestPoint[0] - coord[0]) / xRes;
                const distY = Math.abs(closestPoint[1] - coord[1]) / yRes;
                const distSqr = distX ** 2 + distY ** 2;
                if (distSqr <= hitToleranceSqr) {
                    target.push(feat);
                    continue;
                }
            }
        }
        return target;
    }

    private getVectorInExtent(map: Giro3dMap, extent: Extent, target = []) {
        map.forEachLayer((layer) => {
            if (
                layer !== this._footprintManager?.layer &&
                !isGiro3dMaskLayer(layer) &&
                isGiro3dVectorSource(layer.source)
            ) {
                const extentLayer = extent.as(layer.extent.crs());
                const olExtent = [extentLayer.west(), extentLayer.south(), extentLayer.east(), extentLayer.north()];
                for (const feature of layer.source.source.getFeaturesInExtent(olExtent)) {
                    target.push({ layer, feature });
                }
            }
        });

        return target;
    }

    /**
     * Attach a mask to an elevation layer.
     * @param params Params.
     * @param params.receiver The layer that will receive the mask.
     * @param params.emitter The layer that emits the mask.
     * @param params.footprint The mask footprint as GeoJSON.
     * @returns A promise that resolves when the mask has been applied.
     */
    async attachMask({
        receiver,
        emitter,
        footprint,
    }: {
        receiver: ScopeElevationLayer;
        emitter: ScopeElevationLayer;
        footprint?: GeometryWithCRS;
    }): Promise<MaskLayer> {
        if (!this._maskLayers.has(emitter)) {
            let maskLayer: MaskLayer;
            if (footprint) {
                // let's use the footprint to create the vector mask.
                // Note that the vector will be rasterized by the mask layer itself anyway.
                maskLayer = LayerManager.createMaskLayerFromFootprint(footprint);
            } else {
                // otherwise we will use a raster mask
                maskLayer = LayerManager.createMaskLayerFromRaster(emitter);
            }
            this._maskLayers.set(emitter, { maskLayer, receivers: new Set() });
        }

        const { maskLayer, receivers } = this._maskLayers.get(emitter);

        const map = this.getRegularMap(receiver);

        receivers.add(receiver.id);

        // The mask is already present
        if (map.getLayers().some((layer) => layer.id === maskLayer.id)) {
            return Promise.resolve(maskLayer);
        }
        return map.addLayer(maskLayer) as Promise<MaskLayer>;
    }

    /**
     * Detaches the mask from the layer.
     *
     * @param params The layer that will receive the mask.
     * @param params.receiver The layer that will receive the mask.
     * @param params.emitter The layer that emits the mask.
     */
    detachMask({ receiver, emitter }: { receiver: ScopeElevationLayer; emitter: ScopeElevationLayer }) {
        if (!this._maskLayers.has(emitter)) {
            throw new Error(`no mask layer associated with emitter '${emitter.id}' could be found`);
        }

        const { maskLayer, receivers } = this._maskLayers.get(emitter);

        const map = this.getRegularMap(receiver);

        map.removeLayer(maskLayer);

        receivers.delete(receiver.id);

        this.cleanupUnusedMasks();
    }

    /**
     * Assigns or remove the clipping plane for relevant maps.
     * @param layer The layer.
     * @param plane The clipping plane. If null, clipping planes are disabled on the associated map(s).
     */
    setClippingPlane(layer: ScopeElevationLayer | ScopeColorLayer, plane?: Plane) {
        let maps: Giro3dMap[];
        if ((layer as ColorLayer).isColorLayer) {
            maps = this.getMapsContaining(layer as ScopeColorLayer);
        } else {
            const map = this.getRegularMap(layer as ScopeElevationLayer);
            maps = [map];
        }

        maps.forEach((map) => {
            if (plane) {
                map.clippingPlanes = [plane];
            } else {
                map.clippingPlanes = null;
            }
            this.notify(map);
        });
    }

    /**
     * @param elevationLayer The layer to remove.
     */
    private removeElevationLayer(elevationLayer: ScopeElevationLayer) {
        if (!this._regularMaps.has(elevationLayer)) {
            return;
        }

        const map = this._regularMaps.get(elevationLayer);

        this._regularMaps.delete(elevationLayer);

        for (const { receivers } of this._maskLayers.values()) {
            if (receivers.has(elevationLayer.id)) {
                receivers.delete(elevationLayer.id);
            }
        }

        this.cleanupUnusedMasks();

        this._instance.remove(map);
    }

    private cleanupUnusedMasks() {
        for (const key of [...this._maskLayers.keys()]) {
            const { maskLayer, receivers } = this._maskLayers.get(key);
            if (receivers.size === 0) {
                maskLayer.dispose();
                this._maskLayers.delete(key);
            }
        }
    }

    /**
     * @param colorLayer The layer to remove.
     */
    private removeColorLayerFromAllMaps(colorLayer: ColorLayer) {
        for (const map of this._regularMaps.values()) {
            map.removeLayer(colorLayer);
        }
        for (const map of this._flatMaps.values()) {
            map.removeLayer(colorLayer);
        }
    }

    /**
     * Creates a map to host a single, undraped color layer.
     * @param colorLayer The layer of the map.
     * @returns The created map.
     */
    private createFlatMap(colorLayer: ColorLayer): Giro3dMap {
        const id = `flat-${colorLayer.id}`;
        const newMap = new Giro3dMap(id, {
            extent: colorLayer.extent,
            discardNoData: false,
            hillshading: false,
            backgroundOpacity: 0,
            object3d: this.createMapRoot(id),
            segments: FLAT_MAP_SEGMENTS,
            doubleSided: true,
        });

        // To ensure that flat maps are properly rendered when their opacity is less than 1
        newMap.renderOrder = 1;

        this._instance.add(newMap);

        return newMap;
    }

    private createMapRoot(name: string) {
        const root = new THREE.Group();
        root.name = name;

        this._root.add(root);

        return root;
    }

    /**
     * Creates a mask layer from the raster source of the elevation layer.
     * @param source The mask source.
     * @returns The mask layer.
     */
    private static createMaskLayerFromRaster(source: ElevationLayer): MaskLayer {
        const id = `mask-${Math.random()}`;

        const mask = new MaskLayer({
            name: id,
            extent: source.extent,
            source: source.source as ImageSource,
            maskMode: MaskMode.Inverted,
        });

        return mask;
    }

    /**
     * Creates a mask layer from the GeoJSON footprint.
     * @param footprint The GeoJSON footprint.
     * @returns The mask layer.
     */
    private static createMaskLayerFromFootprint(footprint: GeometryWithCRS): MaskLayer {
        // The mask layer uses an opaque fill style.
        const style = new Style({
            fill: new Fill({ color: 'white' }),
        });

        const id = `mask-${Math.random()}`;
        const mask = new MaskLayer({
            name: id,
            maskMode: MaskMode.Inverted,
            source: new VectorSource({
                data: footprint,
                format: new GeoJSONFormat(),
                dataProjection: footprint.crs.properties.name,
                style,
            }),
        });

        return mask;
    }

    /**
     * Creates a map to host elevation and optionally color layers.
     * @param elevationLayer The elevation layer.
     * @returns The created map.
     */
    private async createRegularMap(elevationLayer: ElevationLayer): Promise<Giro3dMap> {
        const id = `regular-${elevationLayer.id}`;
        // Construct and add a new Map
        const newMap = new Giro3dMap(id, {
            extent: elevationLayer.extent,
            discardNoData: true,
            object3d: this.createMapRoot(id),
            hillshading: {
                enabled: this._hillshadingParams.enabled,
                intensity: this._hillshadingParams.intensity,
                elevationLayersOnly: true,
                azimuth: this._hillshadingParams.azimuth,
                zenith: this._hillshadingParams.zenith,
            },
            contourLines: {
                enabled: this._contourLineParams.enabled,
                interval: this._contourLineParams.primaryInterval,
                secondaryInterval: this._contourLineParams.secondaryInterval,
                color: this._contourLineParams.color,
                opacity: this._contourLineParams.opacity,
            },
            segments: this._segments,
            doubleSided: true,
        });

        newMap.renderOrder = 0;

        await this._instance.add(newMap);

        if (this._footprintManager) {
            await newMap.addLayer(this._footprintManager.layer);
        }

        return newMap;
    }

    public sampleElevation(points: THREE.Vector2[], layerNames: string[]): Map<DatasetId, number[]> {
        const layers = layerNames.map((layerId) => {
            const layer = this.getLayerByName(layerId as string);
            if (layer === undefined) {
                throw new Error(`unknown layer id: ${layerId}`);
            }
            return layer;
        });

        const lineCoordinates = points.map(
            (point) => new Coordinates(this._instance.referenceCrs, point.x, point.y, 0)
        );

        const elevationProfiles = new Map();

        layers.forEach((layer) => {
            const map = this._regularMaps.get(layer as ScopeElevationLayer);
            elevationProfiles.set(
                layer.name,
                lineCoordinates.map((coordinates) => {
                    const result = map.getElevation({ coordinates });
                    if (result.samples.length > 0) {
                        result.samples.sort((a, b) => a.resolution - b.resolution);
                        return result.samples[0].elevation;
                    }
                    return null;
                })
            );
        });

        return elevationProfiles;
    }
}

export default LayerManager;

export { DrapingMode };
