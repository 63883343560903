import { getDatasetColorHexString } from 'services/DatasetColors';
import giro3dService from '../../../services/Giro3dService';

const ElevationTooltip = (a) => {
    const { lookUp, payload, active } = a;
    if (active && payload && payload.length !== 0) {
        giro3dService.updateCoordinates(
            {
                point: {
                    x: payload[0].payload.x,
                    y: payload[0].payload.y,
                    z: Math.max(...payload.map((item) => item.value)),
                },
                picked: true,
                color: `#${getDatasetColorHexString(payload.reduce((max, obj) => (obj.value > max.value ? obj : max)).dataKey)}`,
            },
            true
        );
        return (
            <div className="elevation-profile-tooltip">
                <p>{payload[0].payload.datasetName}</p>
                <p>
                    <span>x: </span>
                    {payload[0].payload.x.toFixed(0)}
                </p>
                <p>
                    <span>y: </span>
                    {payload[0].payload.y.toFixed(0)}
                </p>
                {payload.map((item) => (
                    <p key={item.dataKey}>
                        <span style={{ color: `#${getDatasetColorHexString(item.dataKey)}` }}>
                            {lookUp[item.dataKey]}:{' '}
                        </span>
                        {item.value.toFixed(0)}
                    </p>
                ))}
                <p>
                    <span>Distance: </span>
                    {payload[0].payload.d.toFixed(0)}
                </p>
            </div>
        );
    }
    return null;
};

export default ElevationTooltip;
