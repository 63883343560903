import {
    LayerState,
    HasAttributes,
    HasOpacity,
    HasRadius,
    HasAmplitude,
    HasArrows,
    HasOverlayColor,
    HasColoringMode,
} from 'types/LayerState';
import * as layersSlice from 'redux/layers';
import LineLayer, { ConstructorParams, Settings as BaseSettings } from './LineLayer';
import { DEFAULT_TRACKLINE_SETTINGS } from '../../../services/Constants';
import ArrowHeadCollection from './ArrowHeadCollection';
import LayerStateObserver from '../LayerStateObserver';

export interface Settings extends BaseSettings {
    isShowingArrowHelpers: boolean;
    arrowSpacing: number;
    arrowScale: number;
}

type L = LayerState &
    HasOverlayColor &
    HasAttributes &
    HasRadius &
    HasOpacity &
    HasArrows &
    HasAmplitude &
    HasColoringMode;

export default class TracklineLayer extends LineLayer<Settings, L> {
    readonly isCableLayer = true as const;

    private _arrowHeads: ArrowHeadCollection;

    /**
     * Creates an instance of TracklineLayer.
     * @param params The parameters
     */
    constructor(params: ConstructorParams) {
        super(params);

        this.type = 'TracklineLayer';
        this.settings.isShowingArrowHelpers = DEFAULT_TRACKLINE_SETTINGS.ARROWS;
        this.settings.arrowSpacing = DEFAULT_TRACKLINE_SETTINGS.ARROW_SPACING;
        this.settings.arrowScale = DEFAULT_TRACKLINE_SETTINGS.ARROW_SCALE;
        this.settings.radius = DEFAULT_TRACKLINE_SETTINGS.THICKNESS;
    }

    protected subscribeToStateChanges(observer: LayerStateObserver<L>): void {
        super.subscribeToStateChanges(observer);

        observer.subscribe(layersSlice.getShowArrows(this._layerState), (v) => this.showArrows(v));
        observer.subscribe(layersSlice.getArrowScale(this._layerState), (v) => this.setArrowScale(v));
        observer.subscribe(layersSlice.getArrowSpacing(this._layerState), (v) => this.setArrowSpacing(v));

        observer.subscribe(layersSlice.getShowAmplitude(this._layerState), (v) => this.showAmplitude(v));
        observer.subscribe(layersSlice.getAmplitudeRange(this._layerState), (v) => this.setAmplitudeRange(v));
        observer.subscribe(layersSlice.getAmplitudeMode(this._layerState), (v) => this.setAmplitudeMode(v));
    }

    override applyOverlay(): void {
        super.applyOverlay();

        const color = this.settings.overlayColor;

        if (this._arrowHeads) {
            this._arrowHeads.color = color;
        }

        this.notifyLayerChange();
    }

    protected override setBrightness(brightness: number): void {
        super.setBrightness(brightness);
        this._arrowHeads?.setBrightness(brightness);
    }

    private showArrows(value: boolean) {
        this.settings.isShowingArrowHelpers = value;
        if (this.initialized) {
            this.removeArrowHelpers();
            if (value) {
                this.addArrowHelpers();
            }
            this.notifyLayerChange();
        }
    }

    private setArrowSpacing(value: number) {
        this.settings.arrowSpacing = value;

        if (this.initialized) {
            if (this.settings.isShowingArrowHelpers) {
                this.removeArrowHelpers();
                this.addArrowHelpers();
            }
            this.notifyLayerChange();
        }
    }

    private setArrowScale(value: number) {
        this.settings.arrowScale = value;

        if (this.initialized) {
            if (this.settings.isShowingArrowHelpers) {
                this.removeArrowHelpers();
                this.addArrowHelpers();
            }
            this.notifyLayerChange();
        }
    }

    private async addArrowHelpers() {
        this._arrowHeads = new ArrowHeadCollection(this._geometrySource, this._giro3dInstance);
        this._arrowHeads.addArrowHelpers({
            scale: this.settings.arrowScale,
            color: this.settings.overlayColor,
            spacing: this.settings.arrowSpacing,
        });
        this._giro3dInstance.add(this._arrowHeads);
        this.object3d.add(this._arrowHeads.object3d);
    }

    private removeArrowHelpers() {
        this._arrowHeads?.dispose();
        this._arrowHeads = null;
        this.notifyLayerChange();
    }

    async doSetVisibility(visible: boolean) {
        await super.doSetVisibility(visible);
        if (this._arrowHeads) {
            this._arrowHeads.visible = visible;
        }
    }
}
