import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { DEFAULT_GIRO3D_SETTINGS } from 'services/Constants';
import { RootState } from 'store';
import { SerializableColor, toHex, toColor } from 'types/common';
import { View } from 'types/serialization/View';

export type Ticks = { x: number; y: number; z: number };

export type State = {
    visible: boolean;
    showLabels: boolean;
    opacity: number;
    ticks: Ticks;
    color: SerializableColor;
};

const initialState: State = {
    visible: DEFAULT_GIRO3D_SETTINGS.SEISMIC_GRID,
    showLabels: DEFAULT_GIRO3D_SETTINGS.GRID_LABELS,
    color: toHex(DEFAULT_GIRO3D_SETTINGS.GRID_COLOR),
    opacity: DEFAULT_GIRO3D_SETTINGS.GRID_OPACITY,
    ticks: null,
};

const self = (store: RootState) => store.seismicGrid;

// Selectors
export const isVisible = createSelector(self, (s) => s.visible);
export const getLabelVisibility = createSelector(self, (s) => s.showLabels);
export const getColor = createSelector(
    (s) => self(s).color,
    (color) => toColor(color)
);
export const getOpacity = createSelector(self, (s) => s.opacity);
export const getTicks = createSelector(self, (s) => s.ticks);

const slice = createSlice({
    name: 'seismicGrid',
    initialState,
    reducers: {
        reset: () => initialState,
        loadView: (state, action: PayloadAction<View>) => {
            const settings = action.payload.axisgrid;

            if (settings) {
                state.visible = settings.visible ?? state.visible;
                state.color = settings.color ?? state.color;
                state.opacity = settings.opacity ?? state.opacity;
                state.showLabels = settings.labels ?? state.showLabels;
                state.ticks = settings.ticks ?? state.ticks;
            }
        },
        visible: (state, action: PayloadAction<boolean>) => {
            state.visible = action.payload;
        },
        showLabels: (state, action: PayloadAction<boolean>) => {
            state.showLabels = action.payload;
        },
        opacity: (state, action: PayloadAction<number>) => {
            state.opacity = action.payload;
        },
        ticks: (state, action: PayloadAction<Ticks>) => {
            state.ticks = action.payload;
        },
        color: (state, action: PayloadAction<SerializableColor>) => {
            state.color = action.payload;
        },
    },
});

export const reducer = slice.reducer;

// Actions
export const loadView = slice.actions.loadView;
export const setVisibility = slice.actions.visible;
export const setLabelsVisibility = slice.actions.showLabels;
export const setColor = slice.actions.color;
export const setOpacity = slice.actions.opacity;
export const setTicks = slice.actions.ticks;
export const reset = slice.actions.reset;
