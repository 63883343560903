import * as THREE from 'three';
import * as geojsonUtils from 'geojsonUtils';
import * as layersSlice from 'redux/layers';
import ColorMap, { COLORMAP_BOUNDSMODE, getLUT } from 'types/ColorMap';
import {
    Attribute,
    getAttributeMinMax,
    HasAttributes,
    HasOpacity,
    HasSeismicPlane,
    LayerState,
} from 'types/LayerState';
import { SeismicDatasetProperties } from 'types/Dataset';
import { Dispatch } from 'store';
import type { LineString } from 'geojson';
import HoveredItem from 'types/HoveredItem';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import ThreejsGroupLayer, { Settings as BaseSettings } from '../ThreejsGroupLayer';
import { DEFAULT_SEGY_SETTINGS, LAYER_TYPES } from '../../../services/Constants';
import SeismicPlaneEntity from './SeismicPlaneEntity';
import VdsSource from './VdsSource';
import Vector3Array from '../../Vector3Array';
import LayerStateObserver from '../LayerStateObserver';
import { LayerEventMap, ConstructorParams as BaseConstructorParams } from '../Layer';
import IsHoverable from '../IsHoverable';
import LineBuilder from '../lines/LineBuilder';

export type TLayerState = LayerState & HasAttributes & HasOpacity & HasSeismicPlane;

export interface ConstructorParams extends BaseConstructorParams {
    name: string;
    crs: string;
    sourcePath: string;
    orthographic?: boolean;
    properties: SeismicDatasetProperties;
    buildLine?: boolean;
    dispatch: Dispatch;
}

export interface Settings extends BaseSettings {
    offset: number;
    intensityFilter: number;
    staticCorrection: boolean;
    crossSectionInverted: boolean;
    isViewingCrossSection: boolean;
    filterTransparency: boolean;
    isEnvelope: boolean;
    speedmoduleMs: number;
}

function getBounds(attribute: Attribute, colorMap: ColorMap) {
    return {
        min: attribute.min,
        max: attribute.max,
        customMin: colorMap.customMin,
        customMax: colorMap.customMax,
    };
}

export interface EventMap extends LayerEventMap {
    'bbox_change': { payload: THREE.Box3 };
}

class SeismicPlaneLayer extends ThreejsGroupLayer<Settings, EventMap, TLayerState> implements IsHoverable {
    readonly isHoverable = true as const;
    private readonly _properties: SeismicDatasetProperties;
    private readonly _crs: string;
    protected _seismicPlaneLoaded: boolean;
    protected _seismicPlaneVisible: boolean;
    protected _buildLine: boolean;
    protected readonly name: string;
    protected _isLoadingSeismicPlane: boolean;
    protected _entity: SeismicPlaneEntity;
    protected _line: Line2;
    protected _adjustedCoordinates: Vector3Array;
    protected _rawCoordinates: THREE.Vector3[];
    private readonly _orthographic: boolean;
    protected _totalLength: number;
    protected _curve: THREE.CatmullRomCurve3;
    protected calc: { curveLength: number; depthDiff: number; crossSection: THREE.Plane };
    protected origin: THREE.Vector3;
    private _currentAttribute: Attribute;
    private _currentColorMap: ColorMap;
    private _builder: LineBuilder;
    private _colorMapProperties: {
        attributeName: string;
        boundsMode: COLORMAP_BOUNDSMODE;
        min: number;
        max: number;
        lut: THREE.Color[];
    };
    protected _sourcePath: string;

    constructor(params: ConstructorParams) {
        super(params);
        const { properties, name, crs, sourcePath } = params;
        this._properties = properties;
        this._sourcePath = sourcePath;
        this._buildLine = params.buildLine ?? true;

        this._orthographic = params.orthographic ?? false;
        this._crs = crs;

        this.name = name;
        this._seismicPlaneVisible = false;
        this._seismicPlaneLoaded = false;

        this.assignInitialValues();
    }

    getHoverInfo(): HoveredItem {
        return {
            name: this.name,
            itemType: LAYER_TYPES.SEISMIC,
        };
    }

    protected override subscribeToStateChanges(observer: LayerStateObserver<TLayerState>): void {
        super.subscribeToStateChanges(observer);

        observer.subscribe(layersSlice.getSpeedModuleMs(this._layerState), (v) => this.setSpeedModuleMs(v));
        observer.subscribe(layersSlice.getSeismicOffset(this._layerState), (v) => this.setOffset(v));
        observer.subscribe(layersSlice.getStaticCorrection(this._layerState), (v) => this.setStaticCorrection(v));
        observer.subscribe(layersSlice.getFilterTransparency(this._layerState), (v) => this.setFilterTransparency(v));
        observer.subscribe(layersSlice.getIntensityFilter(this._layerState), (v) => this.setIntensityFilter(v));
        observer.subscribe(layersSlice.getActiveAttributeAndColorMap(this._layerState), (v) =>
            this.setColormapProperties(v)
        );
    }

    setColormapProperties(arg: { attribute: Attribute; colorMap: ColorMap }): void {
        const { attribute, colorMap } = arg;

        this._currentAttribute = attribute;
        this._currentColorMap = colorMap;
        if (attribute) {
            const { min, max } = getAttributeMinMax(attribute, colorMap);
            this._colorMapProperties = {
                boundsMode: colorMap.boundsMode,
                attributeName: attribute.name,
                min,
                max,
                lut: getLUT(colorMap),
            };

            this.applyColorMap();
        }
    }

    private applyColorMap() {
        if (this._seismicPlaneLoaded) {
            this._updateMaterials();
            this.notifyLayerChange();
        }
    }

    hover(hover: boolean): void {
        const brightness = hover ? 0.25 : 0;

        if (this._line) {
            this._builder.setBrightness(this._line, brightness);
            this.notifyLayerChange();
        }
    }

    get3dElement() {
        return this.object3d;
    }

    getLoading() {
        return this._entity?.loading ?? false;
    }

    protected updateLineGeometry() {
        this.processCoordinates(this._rawCoordinates);
        this.createCurve();

        if (this._buildLine) {
            this._builder.dispose(this._line);
            this._line = this._builder.create({
                opacity: 1,
                coordinates: {
                    points: this._adjustedCoordinates,
                    origin: new THREE.Vector3(0, 0, 0),
                },
                color: new THREE.Color('blue'),
            });

            this.prepareLine();
        }

        this.object3d.position.copy(this.origin);
    }

    private updateLineOffset() {
        this._line?.position.setZ(this.settings.offset);
        this._line?.updateMatrixWorld(true);
    }

    private updateSeismicPlaneOffset() {
        this._entity.setZOffset(this.settings.offset);
    }

    showSeismicPlane(show: boolean) {
        this._seismicPlaneVisible = show;

        if (show && !this._seismicPlaneLoaded && !this._isLoadingSeismicPlane) {
            this._loadSeismicPlane();
        }

        if (this._entity) {
            this._entity.visible = show;
        }
    }

    getBoundingBox(): THREE.Box3 {
        return new THREE.Box3()
            .setFromPoints(this._rawCoordinates)
            .translate(new THREE.Vector3(0, 0, this.settings.offset));
    }

    private prepareLine() {
        const line = this._line;

        line.renderOrder = 1;

        line.name = `${this.name}-line`;
        line.userData.datasetId = this.datasetId;
        line.userData.sourceFileId = this.sourceFileId;
        line.position.setZ(this.settings.offset);

        this.object3d.add(this._line);

        this._line.updateMatrixWorld(true);
    }

    protected async initOnce() {
        await super.initOnce();

        const geometry = this.getFootprint() as LineString;

        const transformed = geojsonUtils.transform(geometry, this._crs, this._giro3dInstance.referenceCrs);
        this._rawCoordinates = transformed.coordinates.map(([x, y, z]) => new THREE.Vector3(x, y, z));

        this.processCoordinates(this._rawCoordinates);
        this.createCurve();

        this.calc = {
            curveLength: 0.0,
            depthDiff: this.computeDepth(DEFAULT_SEGY_SETTINGS.SPEEDMODULE_MS),
            crossSection: undefined,
        };

        this.object3d.userData.datasetId = this.datasetId;
        this.object3d.userData.sourceFileId = this.sourceFileId;

        this._giro3dInstance.add(this.get3dElement());

        if (this._buildLine) {
            this._builder = new LineBuilder({ thickness: 2 });

            this._line = this._builder.create({
                color: new THREE.Color(0x0000ff),
                opacity: 1,
                coordinates: {
                    points: this._adjustedCoordinates,
                    origin: new THREE.Vector3(0, 0, 0),
                },
            });

            this.prepareLine();
            this.object3d.add(this._line);
        }

        this.object3d.position.copy(this.origin);
        this.object3d.name = `SeismicPlaneLayer-${this.datasetId}`;
        this.object3d.updateMatrixWorld(true);
        this.initialized = true;
    }

    protected onDispose(): void {
        if (this._entity) {
            this._giro3dInstance.remove(this._entity);
        }
        if (this._line) {
            this._line.material.dispose();
            this._line.geometry.dispose();
        }
        super.onDispose();
    }

    private _updateMaterials() {
        if (this._entity && this._currentAttribute) {
            const colorMap = this._layerObserver.select(
                layersSlice.getColorMap(this._layerState, this._currentAttribute.name)
            );

            this._entity.setMaterialParameters({
                opacity: this.settings.opacity,
                filterTransparency: this.settings.filterTransparency,
                intensityFilter: this.settings.intensityFilter,
                lut: this._colorMapProperties.lut,
                bounds: getBounds(this._currentAttribute, colorMap),
                customBoundsMode: colorMap.boundsMode === COLORMAP_BOUNDSMODE.CUSTOM,
                depthDiff: this.calc.depthDiff,
            });
            this.notifyLayerChange();
        }
    }

    async _loadSeismicPlane() {
        if (!this.initialized) {
            await this.init();
        }
        if (!this._entity) {
            this._isLoadingSeismicPlane = true;
            const colorMap = this._layerObserver.select(
                layersSlice.getColorMap(this._layerState, this._currentAttribute.name)
            );

            const id = `SeismicPlaneEntity-${this.sourceFileId}${this._orthographic ? '-2D' : ''}`;

            this._entity = new SeismicPlaneEntity(id, {
                datasetId: this.datasetId,
                sourceFileId: this.sourceFileId,
                instance: this._giro3dInstance,
                object3d: new THREE.Group(),
                bounds: getBounds(this._currentAttribute, colorMap),
                curve: this._curve,
                loader: new VdsSource(this._sourcePath, this._properties, this.calc.depthDiff),
                depthDiff: this.calc.depthDiff,
                offset: this._orthographic ? 0 : this.settings.offset,
                totalLength: this._totalLength,
                orthographic: this._orthographic || false,
            });

            this._giro3dInstance.add(this._entity);
            this.object3d.add(this._entity.object3d);
            this.object3d.updateMatrixWorld();
        }

        await this._entity.preprocess();

        this._updateMaterials();

        this._isLoadingSeismicPlane = false;
        this._seismicPlaneLoaded = true;
    }

    protected computeDepth(speedmoduleMs) {
        return 0.5 * speedmoduleMs * (this._properties.sampleMaxTime - this._properties.sampleMinTime);
    }

    setOpacity(v: number) {
        this.settings.opacity = v;
        this._updateMaterials();
    }

    setOffset(offset: number) {
        this.settings.offset = offset;
        if (this.initialized) {
            this.updateLineOffset();
            if (this._seismicPlaneLoaded) {
                this.updateSeismicPlaneOffset();
            }
            this.object3d.updateMatrixWorld();
            this.notifyLayerChange();
        }
    }

    setSpeedModuleMs(speedmoduleMs) {
        this.settings.speedmoduleMs = speedmoduleMs;
        if (this.initialized) {
            this.calc = {
                curveLength: this.calc.curveLength,
                depthDiff: this.computeDepth(speedmoduleMs),
                crossSection: this.calc.crossSection,
            };
            this._updateMaterials();

            if (this._seismicPlaneLoaded) {
                this._entity.dispose();
                this._loadSeismicPlane();
            }
        }
    }

    setStaticCorrection(value: boolean) {
        this.settings.staticCorrection = value;
        if (this.initialized) {
            this.updateLineGeometry();
            this.notifyLayerChange();
            if (this._seismicPlaneLoaded) {
                this._entity.setCurve(this._curve);
            }
        }
    }

    setIntensityFilter(value: number) {
        this.settings.intensityFilter = value;
        if (this._seismicPlaneLoaded) {
            this._updateMaterials();
        }
        this.notifyLayerChange();
    }

    /**
     * @param rawCoordinates The raw coordinates.
     */
    protected processCoordinates(rawCoordinates: THREE.Vector3[]) {
        this.origin = rawCoordinates[0].clone();
        const fixedDepth = -(0.5 * this.settings.speedmoduleMs * this._properties.sampleMinTime);
        const result = new Vector3Array({ length: rawCoordinates.length });

        if (this.settings.staticCorrection) {
            // fix the SBP line at a contant depth computed from the minimum TWT.
            this.origin.z = fixedDepth;
            for (let i = 0; i < rawCoordinates.length; i++) {
                const v = rawCoordinates[i];
                const x = v.x - this.origin.x;
                const y = v.y - this.origin.y;
                const z = 0;
                result.set(i, x, y, z);
            }
        } else {
            for (let i = 0; i < rawCoordinates.length; i++) {
                const v = rawCoordinates[i];
                const x = v.x - this.origin.x;
                const y = v.y - this.origin.y;
                const z = v.z - this.origin.z;
                result.set(i, x, y, z);
            }
        }

        this._adjustedCoordinates = result;
    }

    private createCurve() {
        this._curve = new THREE.CatmullRomCurve3(this._adjustedCoordinates.toArray(), false, 'chordal');
        this._curve.arcLengthDivisions = this._adjustedCoordinates.length;
        this._curve.updateArcLengths();
        this._totalLength = this._curve.getLength();
    }

    setFilterTransparency(value: boolean) {
        this.settings.filterTransparency = value;
        if (this._seismicPlaneLoaded) {
            this._updateMaterials();
        }
        this.notifyLayerChange();
    }

    toggleCrossSection(toggleOn: boolean) {
        if (toggleOn) {
            this._giro3dInstance.renderer.clippingPlanes = [this.calc.crossSection];
            this.settings.isViewingCrossSection = true;
        } else {
            this._giro3dInstance.renderer.clippingPlanes = [];
            this.settings.isViewingCrossSection = false;
        }
        this.notifyLayerChange();
    }

    toggleCrossSectionInverted(toggleOn: boolean) {
        if (toggleOn !== this.settings.crossSectionInverted) {
            this.settings.crossSectionInverted = toggleOn;
            this.calc.crossSection.negate();
        }
        this.notifyLayerChange();
    }

    protected setBrightness(brightness: number) {
        if (this._line) {
            this._builder.setBrightness(this._line, brightness);
        }
        if (this._entity) {
            this._entity.setBrightness(brightness);
        }
    }

    shouldInitialize() {
        this.throwIfDisposed();
        return !this.initialized && this.getDatasetVisibility() && this.getMinimapVisibility();
    }

    /**
     * Sets the source file-specific visibility and applies the overall visibility.
     * Even if providing `true`, layer can be hidden if his parent is hidden.
     * @param visible New visibility
     */
    async setSourceFileVisibility(visible: boolean) {
        this.settings.sourceFileVisibility = visible;
        if (this.shouldInitialize()) {
            await this.init();
        }
        this.showSeismicPlane(visible);
    }

    getVisibility(): boolean {
        this.throwIfDisposed();
        return this.getDatasetVisibility() && this.getMinimapVisibility();
    }
}

export default SeismicPlaneLayer;
