import { nanoid } from '@reduxjs/toolkit';

export type Props = {
    value: number;
    min?: number;
    max?: number;
    step: number;
    title: string;
    icon?: string;
    unit?: string;
    onChange: (v: number) => void;
    onBlur?: (v: number) => void;
    arrows?: boolean;
};

function Input(props: Props) {
    const id = nanoid();
    return (
        <li>
            <label htmlFor={id} className="cursor">
                <div>
                    <i className={props.icon} />
                </div>
                <div>
                    <button type="button" id={id}>
                        {props.title}
                    </button>
                </div>
                <div>
                    <input
                        type="number"
                        value={props.value}
                        min={props.min}
                        max={props.max}
                        step={props.step}
                        onChange={(e) => props.onChange(e.target.valueAsNumber)}
                        onBlur={(e) => props.onBlur(e.currentTarget.valueAsNumber)}
                    />
                    <span>{props.unit}</span>
                    {props.arrows ? (
                        <div className="incrementers">
                            <button type="button" onClick={() => props.onChange(props.value + props.step)}>
                                <i className="fas fa-caret-up" />
                            </button>
                            <button type="button" onClick={() => props.onChange(props.value - props.step)}>
                                <i className="fas fa-caret-down" />
                            </button>
                        </div>
                    ) : null}
                </div>
            </label>
        </li>
    );
}

export default Input;
